import React, { useCallback, useEffect, useState } from 'react'
import { Box, CardMedia, Typography } from '@mui/material'
import Slider from 'react-slick'
import { Characteristic } from '@glass/models'
import { QuestionCard } from './QuestionCard'

export type QuestionsProps = {
  characteristics: Characteristic[]
  disabled?: boolean
  firstErrorIndex?: number
  onChange: (value: Characteristic[]) => void
  setActiveIndex?: (v: number) => void
  showError?: boolean
}

export const Questions: React.FC<QuestionsProps> = ({
  characteristics,
  disabled = false,
  firstErrorIndex = 0,
  onChange,
  setActiveIndex = () => {},
  // showError,
}) => {
  let sliderRef: Slider | null = null
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const getDisabledValue = useCallback(
    (item: Characteristic) => {
      return item?.force_yes || disabled
    },
    [disabled],
  )

  const handleChangeIndex = (index: number) => {
    setActiveIndex(index)
    setCurrentIndex(index)
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index: number) => {
      handleChangeIndex(index)
    },
  }

  const handleChangeCharacteristic = (value: Characteristic) => {
    onChange(
      characteristics.map((item) => {
        if (item.id === value.id) {
          return value
        }
        return item
      }),
    )
    if (currentIndex < characteristics.length - 1) sliderRef?.slickGoTo(currentIndex + 1)
  }

  useEffect(() => {
    handleChangeIndex(firstErrorIndex)
    sliderRef?.slickGoTo(firstErrorIndex)
  }, [firstErrorIndex])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginTop: { xs: 0, lg: 4 },
        }}
      >
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <CardMedia
            component='img'
            sx={{ width: 24, height: 24, cursor: 'pointer' }}
            image={process.env.PUBLIC_URL + '/img/chevron-left-gray.svg'}
            onClick={() => {
              sliderRef?.slickPrev()
            }}
          />
          <Box display='flex' gap={1}>
            {characteristics?.map((question, index) => (
              <Box
                key={question.id}
                borderRadius='4px'
                border={index === currentIndex ? '1px solid #F2F2F3' : 'unset'}
                width={24}
                height={24}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Typography
                  fontWeight={index === currentIndex ? '700' : '300'}
                  color={index === currentIndex ? '#9557e8' : '#020E21'}
                >
                  {index + 1}
                </Typography>
              </Box>
            ))}
          </Box>
          <CardMedia
            component='img'
            sx={{ width: 24, height: 24, cursor: 'pointer' }}
            image={process.env.PUBLIC_URL + '/img/chevron-right-gray.svg'}
            onClick={() => {
              sliderRef?.slickNext()
            }}
          />
        </Box>
      </Box>

      <Box sx={{ mt: 2, height: '100%' }}>
        <Box
          sx={{
            position: 'relative',
            m: -2,
          }}
        >
          <Slider ref={(slider) => (sliderRef = slider)} {...sliderSettings}>
            {characteristics?.map((item, index) => (
              <Box key={index}>
                <Box sx={{ m: 2 }}>
                  <QuestionCard
                    characteristic={item}
                    disabled={getDisabledValue(item)}
                    onChange={handleChangeCharacteristic}
                  />
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </>
  )
}
