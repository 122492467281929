import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/system'
import { useFormik } from 'formik'
import { trackPromise } from 'react-promise-tracker'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { array, object, string } from 'yup'
import { AddressInput } from '@glass/components/AddressInput/AddressInput'
import { ChangeAddress } from '@glass/components/ChangeAddress'
import { LicensePlate } from '@glass/components/LicensePlate'
import { WindowSelector } from '@glass/components/WindowSelector'
import { AddressType, CarType, OrderState } from '@glass/enums'
import { REACT_APP_API_DOMAIN_URL } from '@glass/envs'
import { useRetrieveVehData } from '@glass/hooks/useRetrieveVehData'
import { Address, Characteristic, CustomerInfo, FormFieldIds, QuoteDto, VehicleData } from '@glass/models'
import { checkInquiryService } from '@glass/services/apis/check-inquiry.service'
import { createQuoteBEService } from '@glass/services/apis/create-quote-be.service'
import { getCustomerInfoService } from '@glass/services/apis/get-customer-info.service'
import { getQuoteService } from '@glass/services/apis/get-quote.service'
import { getVehicleService } from '@glass/services/apis/get-vehicle.service'
import { formatLicenseNumber } from '@glass/utils/format-license-number/format-license-number.util'
import { UKPhoneRegExp, scrollToElementWithOffset } from '@glass/utils/index'
import { clearRequestedURL, getRequestedURL } from '@glass/utils/session/session.util'
import { QuoteBEDialog } from '../Customer/QuoteDialogBE'

export type CustomerFormBEProps = {
  editMode?: boolean
}

const validationSchema = object({
  registration_number: string().required('Registration number is required'),
  customer_f_name: string().required('First name is required'),
  customer_s_name: string().required('Last name is required'),
  customer_email: string().email('Please enter a valid email').required('Email is required'),
  customer_phone: string().matches(UKPhoneRegExp, 'Please enter a valid phone number').required('Phone is required'),
  customer_address: object().required('Address is required'),
  glass_location: array().min(1, 'Glass Location is required'),
})

export const CustomerFormBE: React.FC<CustomerFormBEProps> = ({ editMode = false }) => {
  const navigate = useNavigate()
  const { licenseNum, quoteId } = useParams()

  const initialValues: QuoteDto = {
    registration_number: licenseNum || '',
    customer_f_name: '',
    customer_s_name: '',
    customer_email: '',
    customer_phone: '',
    customer_address: undefined,
    glass_location: [],
  }

  const inputLicenseRef = useRef<HTMLInputElement | null>(null)
  const isLoaded = useRef(false)

  const [searchParams] = useSearchParams()
  const customerId = searchParams.get('customer_id')
  const userId = searchParams.get('user_id')

  // const [licenseSearchVal, setLicense] = useState(licenseNum || '')
  const [vehData, setVehData] = useState<VehicleData | undefined>()
  // const [billingAddress, setBillingAddress] = useState<Address | undefined>(undefined)
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo | undefined>(undefined)

  // const [comment, setComment] = useState<string>('')
  // const [attachments, setAttachments] = useState<Attachment[]>([])

  const [onSubmitMessage, setOnSubmitMessage] = useState('')

  // temporary things for car selection menu - Rainer
  const [selectedCarType, setSelectedCarType] = useState<CarType>(CarType.THREE_DOOR)

  // for getting the array of broken windows
  const [inquiryCharacteristics, setInquiryCharacteristics] = useState<Characteristic[]>([])

  const [isReloadPage, setIsReloadPage] = useState<boolean>(false)

  const [hasError, setHasError] = useState<boolean>(false)
  const [invalid, setInvalid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showQuoteActive, setShowQuoteActive] = useState<string>('')
  const [showQuoteLostPopup, setShowQuoteLostPopup] = useState<string>('')
  const [forceEdit, setForceEdit] = useState<boolean>(false)
  const [searchProductMetric, setSearchProductMetric] = useState<boolean | string>()

  const brokenWindowsToCustomer = (windows: string[]) => {
    formik.setFieldValue(FormFieldIds.GLASS_LOCATION, windows)
  }

  const inquiryCharacteristicsToCustomer = (data: Characteristic[]) => {
    setInquiryCharacteristics(data)
  }

  const searchProductMetricToCustomer = (data: boolean | string) => {
    setSearchProductMetric(data)
  }

  const getCustomerInfo = () => {
    if (customerId) {
      trackPromise(
        getCustomerInfoService(Number(customerId)).then((res) => {
          if (res.success) {
            setCustomerInfo(res.data?.[0])
          }
        }),
      )
    }
  }

  const retrieveVehData = (data: CarType) => {
    setSelectedCarType(data)
  }

  useRetrieveVehData(vehData, retrieveVehData)

  function handleSubmitClick(values: QuoteDto) {
    if (invalid) {
      formik.setFieldError(FormFieldIds.REGISTRATION_NUMBER, 'Invalid reg. number. Please enter valid reg. number')
      scrollToElementWithOffset(FormFieldIds.REGISTRATION_NUMBER, 60)
      return
    }

    const postData: QuoteDto = {
      customer_f_name: values.customer_f_name?.trim(),
      customer_s_name: values.customer_s_name?.trim(),
      customer_phone: values.customer_phone?.trim(),
      customer_email: (values.customer_email || '').trim(),
      customer_address: {
        postcode: values?.customer_address?.postcode || '',
        latitude: values?.customer_address?.latitude || '',
        longitude: values?.customer_address?.longitude || '',
        line_1: values?.customer_address?.line_1 || '',
        line_2: values?.customer_address?.line_2 || '',
        line_3: values?.customer_address?.line_3 || '',
        line_4: values?.customer_address?.line_4 || '',
        locality: values?.customer_address?.locality || '',
        town_or_city: values?.customer_address?.town_or_city || '',
        county: values?.customer_address?.county || '',
        district: values?.customer_address?.district || '',
        country: values?.customer_address?.country || '',
      },
      registration_number: values?.registration_number?.replace(' ', ''),
      glass_location: (values?.glass_location || []).map((item) => item.toLowerCase()),
      inquiry_characteristics: inquiryCharacteristics,
      customer_id: Number(customerId),
      user_id: Number(userId),
      search_product_metric: searchProductMetric,
    }

    if (getRequestedURL()) {
      postData.fe_requested_url = getRequestedURL()
    }
    trackPromise(
      createQuoteBEService(postData).then((res) => {
        if (res.success) {
          navigate('/quote/be/preview/' + res.data.fe_token)
          clearRequestedURL()
        } else {
          toast(res.message)
          setOnSubmitMessage(res?.message)
        }
      }),
    )
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      handleSubmitClick(values)
    },
    validationSchema: validationSchema,
  })

  const fetchVehData = async (license: string | undefined, hasInputChange = false) => {
    if (!!license) {
      const resCheckInquiry = await trackPromise(
        (async () => {
          const res = await checkInquiryService({ registration_number: license })
          return res
        })(),
      )
      if ((!!quoteId && hasInputChange) || !quoteId) {
        if (resCheckInquiry.success && resCheckInquiry?.data?.result?.fe_token) {
          const orderState = resCheckInquiry.data.result?.order_state
          const fe_token = resCheckInquiry.data.result.fe_token
          if ((editMode && formik.values?.registration_number !== license) || !editMode) {
            inputLicenseRef?.current?.blur()
            if (orderState === OrderState.LOST) {
              setShowQuoteLostPopup(fe_token)
            } else {
              setShowQuoteActive(fe_token)
            }
            localStorage.removeItem('currentInquiry')
            return
          }
        }
      }
      // fetch vehicle data
      trackPromise(
        getVehicleService(license)
          .then((res) => {
            if (res.success && res.data?.Model) {
              inputLicenseRef?.current?.blur()
              setVehData(res.data)
              setHasError(false)
              setInvalid(false)
              setIsLoading(false)
              setForceEdit(false)
              // compare licenseNum and license
              // if diff -> reload page
              if (licenseNum?.replace(' ', '') !== license?.replace(' ', '')) {
                setIsReloadPage(true)
              }
            } else {
              setInvalid(true)
              setIsLoading(false)
            }
          })
          .catch(() => {
            setHasError(true)
            setIsLoading(false)
          }),
      )
    } else if ((!license && editMode) || (!license && hasInputChange)) {
      setVehData(undefined)
      setInvalid(true)
    }
  }

  // const handleChangeFiles = (files: Attachment[]) => {
  //   setAttachments(files)
  // }

  const handleVehInputChange = (data: string | undefined) => {
    fetchVehData(data, true)
    formik.setFieldValue(FormFieldIds.REGISTRATION_NUMBER, formatLicenseNumber(data))
  }

  const handleNavigateQuote = (fe_token: string) => {
    trackPromise(
      getQuoteService(fe_token, false).then((res) => {
        if (res.success) {
          window.open(`${REACT_APP_API_DOMAIN_URL}${res.data?.be_url}`, '_target', 'noopener,noreferrer')
        } else {
          toast(res.message)
        }
      }),
    )
  }

  const handleCloseDialogQuote = () => {
    formik.setFieldValue(FormFieldIds.REGISTRATION_NUMBER, '')
    setShowQuoteLostPopup('')
    setShowQuoteActive('')
  }

  useEffect(() => {
    if (isLoaded?.current) {
      return
    }
    if (!!licenseNum) {
      fetchVehData(licenseNum)
      isLoaded.current = true
    }
  }, [licenseNum])

  useEffect(() => {
    if (customerId) {
      getCustomerInfo()
    }
  }, [customerId])

  useEffect(() => {
    if (customerInfo) {
      formik.setValues((values) => ({
        ...values,
        customer_f_name: customerInfo?.customer_f_name || '',
        customer_s_name: customerInfo?.customer_s_name || '',
        customer_email: customerInfo?.customer_email || '',
        customer_phone: customerInfo?.customer_phone || '',
      }))
    }
  }, [customerInfo])

  useEffect(() => {
    if (formik.isSubmitting && (Object.keys(formik.errors).length > 0 || formik.values?.glass_location?.length === 0)) {
      if (formik.errors.registration_number || invalid) {
        scrollToElementWithOffset(FormFieldIds.REGISTRATION_NUMBER, 60)
        return
      }
      if (formik.values?.glass_location?.length === 0) {
        scrollToElementWithOffset(FormFieldIds.GLASS_LOCATION, 120)
        return
      }
      if (
        formik.errors.customer_f_name ||
        formik.errors.customer_s_name ||
        formik.errors.customer_phone ||
        formik.errors.customer_email
      ) {
        scrollToElementWithOffset(FormFieldIds.FIRST_NAME, 60)
        return
      }
      if (formik.errors.customer_address) {
        scrollToElementWithOffset(FormFieldIds.CUSTOMER_ADDRESS, 60)
        return
      }
    }
  }, [formik.isSubmitting, formik.errors, scrollToElementWithOffset, invalid])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <section className='sec-customer'>
        <div className='container'>
          <div className='tab-content'>
            <div className='row' id='scroll-to-top'>
              <Box id={FormFieldIds.REGISTRATION_NUMBER}>
                <LicensePlate
                  placeholderVal={'ENTER REG'}
                  licenseNumber={formik?.values?.registration_number}
                  model={!!vehData ? vehData.Make + ' ' + vehData.Model : 'Make & Model'}
                  handleVehInputChange={handleVehInputChange}
                  disabledEdit={editMode || !!vehData?.Model}
                  hasError={hasError || (formik.touched?.registration_number && !!formik.errors?.registration_number)}
                  invalid={invalid}
                  isLoading={isLoading}
                  autoFocus={!formik?.values?.registration_number}
                  inputLicenseRef={inputLicenseRef}
                  forceEdit={forceEdit}
                  setForceEdit={setForceEdit}
                />
              </Box>
              <br />
            </div>
            <div className='row text-center'>
              <div className='col-md-9 mx-auto'>
                <div>
                  <div id='scroll-focus'>
                    <h2 className='text-blue  text-24'>Select Broken Glasses</h2>
                    <p>Tap directly or select below</p>
                    {formik.touched?.glass_location && !!formik.errors?.glass_location && (
                      <p className='form-text-error' style={{ textAlign: 'center', fontSize: '1rem' }}>
                        Please select broken glasses
                      </p>
                    )}
                  </div>

                  <div className='parent'>
                    <Box id={FormFieldIds.GLASS_LOCATION}>
                      {/* car image display */}
                      <WindowSelector
                        carType={selectedCarType}
                        setCarType={setSelectedCarType}
                        brokenWindowsToCustomer={brokenWindowsToCustomer}
                        brokenWindowsToComponent={[]}
                        registrationNumber={formik?.values?.registration_number?.replace(' ', '')}
                        onChangeCharacteristics={inquiryCharacteristicsToCustomer}
                        isReloadPage={isReloadPage}
                        setIsReloadPage={setIsReloadPage}
                        searchProductMetricToCustomer={searchProductMetricToCustomer}
                        answerSearchProductMetric={searchProductMetric}
                      />
                    </Box>

                    {/* <br />
                    <br />
                    <p className='fs-18 text-blue'>Your comments (optional)</p> */}

                    {/* {comments.map((item, index) => (
                      <div key={index} className='text-left'>
                        <p>
                          <strong>Comment {index + 1}: </strong>
                          {item.comment}
                        </p>
                        <AddPictures disabled={true} attachments={item.attachments} />
                      </div>
                    ))} */}

                    {/* <div className='form-group mb-4'>
                      <textarea
                        name=''
                        id=''
                        rows={4}
                        className='form-control h-auto'
                        placeholder='Details for glass or any other comment.'
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                    </div> */}

                    {/* <AddPictures attachments={attachments} onChangeFiles={handleChangeFiles} /> */}
                    {/* <small className='d-block mt-2'>*Recommended</small> */}
                    <form className='my-md-5 my-4'>
                      <p className='fs-18 text-blue'>Fill your personal details</p>
                      <br />
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group mb-4'>
                            <div className='h6 text-left text-black-50'>First name</div>
                            <input
                              type='text'
                              className={
                                formik.touched.customer_f_name && !!formik.errors.customer_f_name
                                  ? 'form-control form-not-filled'
                                  : 'form-control'
                              }
                              placeholder='First name'
                              value={formik.values?.customer_f_name}
                              onChange={(e) => formik.setFieldValue(FormFieldIds.FIRST_NAME, e.target.value)}
                              id={FormFieldIds.FIRST_NAME}
                              name={FormFieldIds.FIRST_NAME}
                              onFocus={() => {
                                scrollToElementWithOffset(FormFieldIds.FIRST_NAME, 100)
                              }}
                            />
                            {formik.touched.customer_f_name && !!formik.errors.customer_f_name && (
                              <p className='form-text-error'>{formik.errors?.customer_f_name}</p>
                            )}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group mb-4'>
                            <div className='h6 text-left text-black-50'>Last name</div>
                            <input
                              type='text'
                              className={
                                formik.touched.customer_s_name && !!formik.errors.customer_s_name
                                  ? 'form-control form-not-filled'
                                  : 'form-control'
                              }
                              placeholder='Last name'
                              value={formik.values?.customer_s_name}
                              onChange={(e) => formik.setFieldValue(FormFieldIds.LAST_NAME, e.target.value)}
                              id={FormFieldIds.LAST_NAME}
                              name={FormFieldIds.LAST_NAME}
                              onFocus={() => {
                                scrollToElementWithOffset(FormFieldIds.LAST_NAME, 100)
                              }}
                            />
                            {formik.touched.customer_s_name && !!formik.errors.customer_s_name && (
                              <p className='form-text-error'>{formik.errors?.customer_s_name}</p>
                            )}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group mb-4'>
                            <div className='h6 text-left text-black-50'>Email</div>
                            <input
                              type='text'
                              className={
                                formik.touched.customer_email && !!formik.errors.customer_email
                                  ? 'form-control form-not-filled'
                                  : 'form-control'
                              }
                              placeholder='Email'
                              value={formik.values?.customer_email}
                              onChange={(e) => formik.setFieldValue(FormFieldIds.EMAIL, e.target.value)}
                              id={FormFieldIds.EMAIL}
                              name={FormFieldIds.EMAIL}
                              onFocus={() => {
                                scrollToElementWithOffset(FormFieldIds.EMAIL, 100)
                              }}
                            />
                            {formik.touched.customer_email && !!formik.errors.customer_email && (
                              <p className='form-text-error'>{formik.errors?.customer_email}</p>
                            )}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group mb-4'>
                            <div className='h6 text-left text-black-50'>Phone</div>
                            <input
                              type='text'
                              className={
                                formik.touched.customer_phone && !!formik.errors.customer_phone
                                  ? 'form-control form-not-filled'
                                  : 'form-control'
                              }
                              placeholder='Phone'
                              disabled
                              value={formik.values?.customer_phone}
                              onChange={(e) => formik.setFieldValue(FormFieldIds.PHONE, e.target.value)}
                              id={FormFieldIds.PHONE}
                              name={FormFieldIds.PHONE}
                              onFocus={() => {
                                scrollToElementWithOffset(FormFieldIds.PHONE, 100)
                              }}
                            />
                            {formik.touched.customer_phone && !!formik.errors.customer_phone && (
                              <p className='form-text-error'>{formik.errors?.customer_phone}</p>
                            )}
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='form-group mb-4'>
                            <div className='d-flex justify-content-between'>
                              <div className='h6 text-left text-black-50'>Postcode</div>
                              {!!customerId && (
                                <ChangeAddress
                                  qid={''}
                                  customerId={Number(customerId)}
                                  addressType={AddressType.DELIVERY}
                                  initialAddress={{
                                    address_id: Number(customerInfo?.address_id) || -1,
                                    address_type: customerInfo?.address_type || AddressType.CONTACT,
                                    line_1: customerInfo?.line_1 || '',
                                    line_2: customerInfo?.line_2 || '',
                                    postcode: customerInfo?.postcode?.toString() || '',
                                    latitude: customerInfo?.latitude?.toString() || '',
                                    longitude: customerInfo?.longitude?.toString() || '',
                                    town_or_city: customerInfo?.town_or_city || '',
                                    county: customerInfo?.county || '',
                                    country: customerInfo?.country || '',
                                    formatted_address: [],
                                  }}
                                  onChangeAddress={(event) => {
                                    formik.setFieldValue(FormFieldIds.CUSTOMER_ADDRESS, event)
                                  }}
                                  isFormBE
                                />
                              )}
                            </div>
                            <AddressInput
                              address={formik.values?.customer_address as Address}
                              formError={(formik.touched.customer_address && !!formik.errors.customer_address) || false}
                              onChange={(event) => {
                                formik.setFieldValue(FormFieldIds.CUSTOMER_ADDRESS, event)
                              }}
                              errorMessage={formik.errors?.customer_address}
                              id={FormFieldIds.CUSTOMER_ADDRESS}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    {/* submit request button */}
                    <div className='row' style={{ marginBottom: '128px' }}>
                      <div className='col-md-7 mx-auto'>
                        <div className='submit-request-msg'>{onSubmitMessage}</div>
                        <button
                          className='btn btn-purple-radius w-100 mb-3'
                          onClick={formik.submitForm}
                          id='submitBtn'
                          disabled={formik.isSubmitting}
                        >
                          {editMode ? 'Save Quote' : 'Submit inquiry'}
                        </button>
                      </div>
                    </div>
                    <Box
                      position='fixed'
                      bottom={0}
                      left={0}
                      width='100vw'
                      zIndex={100}
                      pt={2}
                      pb={2}
                      borderTop='1px solid #f2f2f3'
                      bgcolor='white'
                    >
                      <div className='row'>
                        <div className='col-md-7 mx-auto'>
                          <div className='submit-request-msg'>{onSubmitMessage}</div>
                          <button
                            className='btn btn-purple-radius w-100 mb-2'
                            onClick={formik.submitForm}
                            id='submitBtn'
                            disabled={formik.isSubmitting}
                          >
                            {editMode ? 'Save Quote' : 'Submit inquiry'}
                          </button>
                        </div>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {(!!showQuoteActive || !!showQuoteLostPopup) && (
        <QuoteBEDialog
          onConfirm={() => handleNavigateQuote(showQuoteActive || showQuoteLostPopup)}
          licenseNum={licenseNum}
          isQuoteActive={!!showQuoteActive}
          onClose={handleCloseDialogQuote}
        />
      )}
    </Box>
  )
}
