import moment from 'moment'
import { MonthlyPayment, WorkshopSchedule } from '@glass/models'
import { formatPaymentPrice } from '../customer/customer.utils'

interface Attachment {
  attachment_id: number
  attachment_url: string
}

interface State {
  sequence: number
  name: string
  date: string | boolean
  attachment_ids?: Attachment[]
}

interface Quote {
  fs_id: number
  fs_name: string
  traccar_device_id: string
  state: State[]
}

export function isUpdateNotAllowed(quotes: Quote[]): boolean {
  // Define the sequence number for "Arrived"
  const arrivedSequence = 5

  for (const quote of quotes) {
    for (const state of quote.state) {
      if (state?.sequence >= arrivedSequence && !!state?.date) {
        return true
      }
    }
  }

  return false
}

export function getWorkshopScheduleForToday(schedule: WorkshopSchedule[]): {
  opening_time: number
  closing_time: number
} {
  const today: string = moment().format('ddd').toLowerCase() // Get today's day in short format (e.g., 'mon')
  const todaySchedule = schedule?.find((day) => day.week_day === today)
  if (todaySchedule) {
    const { opening_time, closing_time } = todaySchedule
    const formattedClosingTime = closing_time > 12 ? closing_time - 12 : closing_time
    return { opening_time, closing_time: formattedClosingTime }
  }
  return {
    opening_time: 7,
    closing_time: 7,
  }
}

export function generatePaymentSchedule(months: number, amount: number): MonthlyPayment {
  const debitAmount = amount / 10
  const AMIR = 0.015
  const result: MonthlyPayment = {
    amount: amount,
    interest: 0,
    plan: `${months}-Payment`,
    repayable: amount,
    schedule: [],
    summary: '',
  }
  const financedAmount = amount - debitAmount
  const amountOneMonth = (financedAmount * 100) / months + financedAmount * 100 * AMIR
  const currentDate = moment()
  for (let i = 0; i < months; i++) {
    result.schedule.push({
      amount: amountOneMonth,
      date: currentDate.format('DD MMM YYYY'),
    })
    currentDate.add(1, 'month')
  }

  result.summary = `This loan comprises of ${months} monthly payments of £${formatPaymentPrice(
    Number(amountOneMonth / 100),
  )}, with the first taken immediately on setup.`

  return result
}
