import './quote.css'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, CardMedia } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'
import { trackPromise } from 'react-promise-tracker'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import expand from '@glass/assets/icons/expand.png'
import flag from '@glass/assets/icons/uk-flag.png'
import up from '@glass/assets/icons/up.png'
import close from '@glass/assets/icons/x.png'
import { AddPictures } from '@glass/components/AddPictures'
// import { BeforeAfter } from '@glass/components/BeforeAfter'
// import { Chat } from '@glass/components/Chat'
import { ConfirmDialog } from '@glass/components/ConfirmDialog'
import { OurMethod } from '@glass/components/OurMethod'
import { Payl8r } from '@glass/components/Payl8r'
import { LocationSelection } from '@glass/components/quotePage/LocationSelection'
import { PaymentMethod } from '@glass/components/quotePage/PaymentMethod/PaymentMethod'
import { TimeSelection } from '@glass/components/quotePage/TimeSelection'
import { Questions } from '@glass/components/WindowSelector/Questions'
import { BOOKING_DATE_FORMAT, EMPTY_OFFER, PHONE_NUMBER } from '@glass/constants'
import {
  BeforeAfterType,
  FieldServiceSequence,
  OrderState,
  PaymentMethodType,
  PaymentOptionEnum,
  PaymentStatus,
  QuoteAction,
  QuoteStep,
  WorkingPlace,
} from '@glass/enums'
import { useCalcPriceSummary } from '@glass/hooks/useCalcPriceSummary'
import {
  Address,
  Attachment,
  Offer,
  OptionalOrderLine,
  PaymentOptionDto,
  Quote,
  QuoteDto,
  TimeSlot,
} from '@glass/models'
import { addOptionalProductService } from '@glass/services/apis/add-optional-product.service'
import { beginPaymentAssistService } from '@glass/services/apis/begin-payment-assist.service'
import { getQuoteService } from '@glass/services/apis/get-quote.service'
import { preApprovePaymentService } from '@glass/services/apis/pre-approve-payment.service'
import { removeOptionalProductService } from '@glass/services/apis/remove-optional-product.service'
import { requestAvailabilityService } from '@glass/services/apis/request-availability.service'
import { sendBookingService } from '@glass/services/apis/send-booking.service'
import { updateDeliveryAddressService } from '@glass/services/apis/update-delivery-address.service'
import { updateQuoteService } from '@glass/services/apis/update-quote.service'
import { formatAddress } from '@glass/utils/format-address/format-address.util'
import { formatLicenseNumber } from '@glass/utils/format-license-number/format-license-number.util'
import { mapGlassLocationFromBE } from '@glass/utils/index'
import { scrollToElementWithOffset } from '@glass/utils/scroll-to-element/scroll-to-element.util'
import { slot2Time } from '@glass/utils/slot-to-time/slot-to-time.util'
import { QuoteTracking } from './QuoteTracking'
import { Characteristic } from '../../core/models/quote.model'

export type QuoteProps = {
  quoteCount?: boolean
}

export const QuotePage: React.FC<QuoteProps> = ({ quoteCount = true }) => {
  const { id } = useParams()
  const showButtons = false
  const [quoteDetails, setQuoteDetails] = useState<Quote | undefined>(undefined)
  const [snapValue, setSnapValue] = useState<QuoteStep>(QuoteStep.PAYMENT)
  const [acceptBtn, setAcceptBtn] = useState<QuoteAction>(QuoteAction.GO_TIMESLOT)
  const [timeSlot, setTimeSlot] = useState<TimeSlot | undefined>(undefined)
  const [quoteInfoOpen, setInfoOpen] = useState<boolean>(true)
  const [deliveryAddress, setDeliveryAddress] = useState<Address | undefined>(undefined)
  const [paymentOption, setPaymentOption] = useState<PaymentOptionDto>({
    p_option: PaymentOptionEnum.NONE,
    detail: QuoteAction.NONE,
  })
  const [declinePopup, setDeclinePopup] = useState(false)
  const [declineReason, setDeclineReason] = useState<number>(0)
  const [isBlink, setIsBlink] = useState(false)
  const declineRef = useRef<HTMLInputElement>(null)
  const [tempLicenseNum, setTempLicense] = useState('')
  const navigate = useNavigate()
  const [offersDetails, setOffersDetails] = useState<Offer[]>([EMPTY_OFFER])
  const [optionalOrderLines, setOptionalOrderLines] = useState<OptionalOrderLine[]>([])
  const [PAData, setPAData] = useState<(string | undefined)[]>([])
  const [PAUrl, setPAUrl] = useState<string>('')
  const [warningMsg, setWarningMsg] = useState<string>('')
  const [showBookingMsg, setShowBookingMsg] = useState<boolean>(false)
  const [showRequestAvailabilityMsg, setShowRequestAvailabilityMsg] = useState<boolean>(false)
  const [showConfirmBooking, setShowConfirmBooking] = useState<boolean>(false)
  const [editBooking, setEditBooing] = useState<boolean>(false)
  const [preAttachments, setPreAttachments] = useState<Attachment[]>([])
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [comment, setComment] = useState<string>('')
  const [emailMissing, setEmailMissing] = useState<boolean>(false)
  const [callQuote, setCallQuote] = useState(false)
  const [isResetPaymentMethod, setIsResetPaymentMethod] = useState<boolean>(false)
  const [goToPaymentClicked, setGoToPaymentClicked] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  // const [paymentStripeSuccess, setPaymentStripeSuccess] = useState<boolean>(false)
  const [paymentMethodStatus, setPaymentMethodStatus] = useState<boolean>(false)

  const payl8rState = searchParams.get('payl8r')
  const paPaymentState = searchParams.get('paPayment')
  const stripePaymentState = searchParams.get('stripePayment')

  //change to quote_characteristics from BE for updating
  const listQuestionCharacteristics = useMemo(() => {
    const tempListQuestion: Characteristic[] = []
    if (quoteDetails?.quote_characteristics) {
      Object.keys(quoteDetails?.quote_characteristics)?.forEach((key) => {
        quoteDetails?.quote_characteristics[key]?.forEach((characteristic) => {
          tempListQuestion?.push({ ...characteristic })
        })
      })
    }
    return tempListQuestion
  }, [quoteDetails?.quote_characteristics])

  const isQuotePayment = useMemo(() => {
    if (quoteDetails?.fixglass_payment_state === PaymentStatus.PAID) {
      return true
    }
    return false
  }, [quoteDetails])

  const { totalPrice, totalUnitPrice, totalDiscountPrice } = useCalcPriceSummary(quoteDetails)

  const timeSlotIsChanged = useMemo<boolean>(() => {
    if (!timeSlot) return false
    if (!timeSlot.isFull) {
      return timeSlot.booking_date !== quoteDetails?.booking_date && timeSlot.time_slot !== quoteDetails?.time_slot
    } else {
      return (
        timeSlot.booking_date !== quoteDetails?.request_booking_date &&
        timeSlot.time_slot !== quoteDetails?.request_time_slot
      )
    }
  }, [timeSlot, quoteDetails])

  const deliveryAddressIsChanged = useMemo<boolean>(() => {
    return !!deliveryAddress && formatAddress(deliveryAddress) !== formatAddress(quoteDetails?.delivery_address)
  }, [deliveryAddress, quoteDetails])

  const getQuote = () => {
    if (id) {
      trackPromise(
        getQuoteService(id, quoteCount).then((res) => {
          if (res.success) {
            const data = res?.data
            data.glass_location = mapGlassLocationFromBE(data?.glass_location)
            setQuoteDetails(data)
          } else {
            toast(res.message)
          }
        }),
      )
    }
  }

  const initQuoteState = () => {
    if (!quoteDetails?.order_state) {
      return
    }
    if (goToPaymentClicked) {
      setSnapValue(QuoteStep.PAYMENT)
      return
    }
    if (quoteDetails.order_state === OrderState.NEW) {
      setSnapValue(QuoteStep.NEW)
      return
    }
    if (quoteDetails.order_state === OrderState.OPEN || quoteDetails.order_state === OrderState.CONFIRM) {
      if (!quoteDetails.booking_date && quoteDetails?.fixglass_payment_state === PaymentStatus.PAID) {
        setSnapValue(QuoteStep.OPTIONS)
      } else {
        setSnapValue(QuoteStep.OPEN)
      }
      return
    }

    if (quoteDetails.order_state === OrderState.WAITING_FOR_PAYMENT) {
      if (goToPaymentClicked) {
        setSnapValue(QuoteStep.PAYMENT)
      } else {
        setSnapValue(QuoteStep.CHECKOUT)
      }
      return
    }
    if (quoteDetails.order_state === OrderState.PAYMENT_EXPIRED) {
      setSnapValue(QuoteStep.CHECKOUT)
      return
    }

    if (quoteDetails.order_state === OrderState.WON) {
      setSnapValue(QuoteStep.TRACKING)
      return
    }

    //check flow rebook
    if (quoteDetails.order_state === OrderState.TO_REBOOK) {
      setSnapValue(QuoteStep.REBOOK)
      return
    }
    if (quoteDetails.order_state === OrderState.REQUEST_TO_CANCEL) {
      setSnapValue(QuoteStep.REQUEST_TO_CANCEL)
      return
    }
    if (quoteDetails.order_state === OrderState.LOST) {
      setSnapValue(QuoteStep.LOST)
      return
    }
  }

  const handleSnapChange = (action: QuoteAction) => {
    switch (action) {
      case QuoteAction.GO_TIMESLOT: {
        setSnapValue(QuoteStep.TIMESLOT)
        scrollToElementWithOffset('2')
        return
      }
      case QuoteAction.GO_PAYMENT: {
        setSnapValue(QuoteStep.PAYMENT)
        scrollToElementWithOffset('1')
        return
      }
      case QuoteAction.CONFIRM_BOOKING: {
        handleConfirmBookingChange()
        return
      }
      case QuoteAction.CONTINUE_PA:
      case QuoteAction.CHECK_ELIGIBILITY: {
        confirmPA(PaymentMethodType.ASSIST_FOUR_PAYMENT)
      }
    }
  }

  const confirmPA = (paymentMethodType: PaymentMethodType) => {
    let first: string
    let second: string
    let post: string
    let addr: string
    if (PAData.length === 0) {
      first = quoteDetails?.customer_f_name || ''
      second = quoteDetails?.customer_s_name || ''
      post = quoteDetails?.delivery_address?.postcode || ''
      addr = formatAddress(quoteDetails?.delivery_address, false)
    } else {
      first = PAData[0] || ''
      second = PAData[1] || ''
      post = PAData[3] || ''
      addr = PAData[4] || ''
    }

    trackPromise(
      preApprovePaymentService({
        f_name: first,
        s_name: second,
        addr1: addr,
        postcode: post,
      }).then((res) => {
        if (res.success) {
          if (res.data.approved) {
            PABegin(paymentMethodType)
          }
        }
      }),
    )
  }

  const PABegin = (paymentMethodType: PaymentMethodType) => {
    // create payment API call
    if (id) {
      trackPromise(
        beginPaymentAssistService(id, paymentMethodType).then((res) => {
          if (res.success) {
            // window.open(res.data.url, '_blank', 'noreferrer')
            window.location.href = res.data.url // This will open the link in the current tab
            setPAUrl(res.data.url)
          }
        }),
      )
    }
  }

  const sendBookingData = () => {
    if (id && quoteDetails) {
      const promises: Promise<void>[] = []

      if (timeSlot) {
        promises.push(
          new Promise((resolve) =>
            sendBookingService(id, timeSlot.booking_date, timeSlot.time_slot).then(() => {
              resolve()
            }),
          ),
        )
      }
      if (deliveryAddress && formatAddress(deliveryAddress) !== formatAddress(quoteDetails.delivery_address)) {
        promises.push(
          new Promise((resolve) =>
            updateDeliveryAddressService({
              customer_id: quoteDetails.customer_id,
              address_id: quoteDetails.delivery_address.address_id,
              line_1: deliveryAddress.line_1,
              line_2: deliveryAddress.line_2,
              postcode: deliveryAddress.postcode,
              latitude: deliveryAddress.latitude,
              longitude: deliveryAddress.longitude,
              town_or_city: deliveryAddress.town_or_city,
              county: deliveryAddress.county,
              country: deliveryAddress.country,
            }).then(() => {
              resolve()
            }),
          ),
        )
      }

      trackPromise(
        Promise.all(promises).finally(() => {
          getQuote()
          window.scrollTo({ top: 0, behavior: 'smooth' })
          setShowBookingMsg(true)
          setEditBooing(false)
        }),
      )
    }
  }

  const handleConfirmBookingChange = () => {
    if (!timeSlot) {
      setWarningMsg('Select a time for the repair!')
      return
    }
    if (!deliveryAddress && !quoteDetails?.delivery_address) {
      setWarningMsg('Select a delivery address for the repair!')
      return
    }
    setShowConfirmBooking(true)
  }

  const handleCancelBookingChange = () => {
    initTimeSlot()
    setEditBooing(false)
  }

  const sendRequestAvailability = () => {
    if (id && quoteDetails) {
      const promises: Promise<void>[] = []

      if (timeSlot) {
        promises.push(
          new Promise((resolve) =>
            requestAvailabilityService(id, timeSlot.booking_date, timeSlot.time_slot).then(() => {
              resolve()
            }),
          ),
        )
      }
      if (deliveryAddress && formatAddress(deliveryAddress) !== formatAddress(quoteDetails.delivery_address)) {
        promises.push(
          new Promise((resolve) =>
            updateDeliveryAddressService({
              customer_id: quoteDetails.customer_id,
              address_id: quoteDetails.delivery_address.address_id,
              line_1: deliveryAddress.line_1,
              line_2: deliveryAddress.line_2,
              postcode: deliveryAddress.postcode,
              latitude: deliveryAddress.latitude,
              longitude: deliveryAddress.longitude,
              town_or_city: deliveryAddress.town_or_city,
              county: deliveryAddress.county,
              country: deliveryAddress.country,
            }).then(() => {
              resolve()
            }),
          ),
        )
      }

      trackPromise(
        Promise.all(promises).finally(() => {
          getQuote()
          window.scrollTo({ top: 0, behavior: 'smooth' })
          setShowRequestAvailabilityMsg(true)
          setEditBooing(false)
        }),
      )
    }
  }

  const handleRequestAvailability = () => {
    if (!timeSlot) {
      setWarningMsg('Select a time for the repair!')
      return
    }
    if (!deliveryAddress && !quoteDetails?.delivery_address) {
      setWarningMsg('Select a delivery address for the repair!')
      return
    }
    sendRequestAvailability()
  }

  function handleDecline() {
    setDeclinePopup(true)
  }

  const selectDeclineReason = (option: number) => {
    setDeclineReason(option)
  }

  const PADataToParent = (data: (string | undefined)[]) => {
    setPAData(data)
  }

  const backToCustomer = () => {
    const licenseReg = quoteDetails?.registration_number.replace(' ', '')
    navigate(`/customer/edit/${licenseReg}/${id}`)
  }

  const timeSlotToParent = (data: TimeSlot) => {
    setTimeSlot(data)
    setWarningMsg('')
  }

  const deliveryAddressToParent = (data: Address | undefined) => {
    setDeliveryAddress(data)
  }

  const handleChangePaymentMethod = (pOption: PaymentOptionDto) => {
    setPaymentOption(pOption)
  }

  const payAssistToParent = (paymentMethodType?: PaymentMethodType) => {
    confirmPA(paymentMethodType || PaymentMethodType.ASSIST_FOUR_PAYMENT)
  }

  const handleCheckOptionalOrderLine = (orderLineId: number, optionalLineId: number, checked: boolean) => {
    if (!id || isQuotePayment) return
    if (checked) {
      addOptionalProductService(id, optionalLineId).then((res) => {
        if (res.success) {
          setIsResetPaymentMethod(true)
          getQuote()
        }
      })
    } else {
      removeOptionalProductService(id, orderLineId, optionalLineId).then((res) => {
        if (res.success) {
          setIsResetPaymentMethod(true)
          getQuote()
        }
      })
    }
  }

  const initTimeSlot = () => {
    if (quoteDetails?.booking_date) {
      setTimeSlot({
        booking_date: quoteDetails.booking_date,
        time_slot: quoteDetails.time_slot,
        isFull: false,
      })
    } else if (quoteDetails?.request_booking_date) {
      setTimeSlot({
        booking_date: quoteDetails.request_booking_date,
        time_slot: quoteDetails.request_time_slot,
        isFull: true,
      })
    } else {
      setTimeSlot(undefined)
    }
  }

  const handleChangeFiles = (files: Attachment[]) => {
    setAttachments(files)
  }

  const handleClickUpload = () => {
    let textComment = comment
    if (!!comment && attachments?.length > 0) {
      textComment = `${comment} and Upload Pictures`
    } else if (!comment && attachments?.length > 0) {
      textComment = 'Upload Pictures'
    }
    const postData: QuoteDto = {
      customer_f_name: (quoteDetails?.customer_f_name || '').trim(),
      customer_s_name: (quoteDetails?.customer_s_name || '').trim(),
      customer_phone: (quoteDetails?.customer_phone || '').trim(),
      customer_email: (quoteDetails?.customer_email || '').trim(),
      registration_number: (quoteDetails?.registration_number || '').trim(),
      glass_location: (quoteDetails?.glass_location || []).map((item) => item.toLowerCase()),
      customer_comments: {
        comment: textComment,
        attachments: attachments,
      },
      inquiry_characteristics: listQuestionCharacteristics,
      search_product_metric: quoteDetails?.search_product_metric,
    }

    trackPromise(
      updateQuoteService({ fe_token: id, ...postData }).then((res) => {
        if (res.success) {
          setAttachments([])
          setComment('')
          getQuote()
        }
      }),
    )
  }

  const renderPictures = () => {
    return !!quoteDetails ? (
      <div className='p-1 mb-3'>
        {quoteDetails?.customer_comments?.map((item, index) => (
          <div key={index} className='text-left'>
            <p>
              <strong>Comment {index + 1}: </strong>
              {item?.comment}
            </p>
            <AddPictures size='small' disabled={true} attachments={item?.attachments} />
          </div>
        ))}

        <div className='form-group mb-4'>
          <textarea
            name=''
            id=''
            rows={4}
            className='form-control h-auto'
            placeholder='Details for glass or any other comment.'
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>

        {!preAttachments.length && !attachments.length && <div className='no-pictures'>No Pictures Added</div>}
        <Box display='flex' justifyContent='flex-start' alignItems='center'>
          <AddPictures
            size='small'
            showUpload={true}
            attachments={attachments}
            onChangeFiles={handleChangeFiles}
            onClickUpload={handleClickUpload}
          />
          {attachments?.length === 0 && !!comment ? (
            <button className='btn-stroked ms-3' onClick={handleClickUpload}>
              <label style={{ cursor: 'pointer' }}>Confirm Update</label>
            </button>
          ) : null}
        </Box>
      </div>
    ) : (
      <></>
    )
  }

  useEffect(() => {
    // Get Quote Data
    if (id) {
      getQuote()
    }

    // scroll to top on page load
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined

    if (id) {
      if (payl8rState === 'success' || paPaymentState === 'success' || stripePaymentState === 'success') {
        // Set up the timer to call API after 15 seconds
        timer = setTimeout(() => {
          getQuote()
          scrollToElementWithOffset('1')
        }, Number(process.env.REACT_APP_SUCCESS_PAYMENT_WAITING_TIME || 15000))
        // Clean up interval when component unmounts or condition changes
        return () => clearTimeout(timer)
      }
    }
    // Clean up when condition is false
    return () => clearTimeout(timer)
  }, [id, payl8rState, paPaymentState, stripePaymentState])

  useEffect(() => {
    if (quoteDetails) {
      initQuoteState()
    }
  }, [quoteDetails, goToPaymentClicked])

  useEffect(() => {
    if (quoteDetails) {
      const optionalOrderLines = quoteDetails.optional_order_lines || []
      if (quoteDetails.order_state === OrderState.OPEN) {
        if (quoteDetails.order_lines?.length)
          setOffersDetails(
            quoteDetails.order_lines.filter(
              (item) => optionalOrderLines.findIndex((x) => x.order_line_id === item.order_line_id) < 0,
            ),
          )
        setOptionalOrderLines(optionalOrderLines)
      } else {
        if (quoteDetails.order_lines?.length) setOffersDetails(quoteDetails.order_lines)
        setOptionalOrderLines([])
      }

      if (quoteDetails.order_state == OrderState.CONFIRM && quoteDetails.booking_date) {
        setIsBlink(true)
      }
      setTempLicense(formatLicenseNumber(quoteDetails.registration_number))
      initTimeSlot()

      let pictures: Attachment[] = []
      quoteDetails.customer_comments?.forEach((comment) => {
        pictures = pictures.concat(comment.attachments)
      })
      setPreAttachments(pictures)
    }
  }, [quoteDetails])

  useEffect(() => {
    // change between accept and next buttons names and styling
    const acceptSelector = document.getElementById('accept-btn')
    if (
      (!!timeSlot &&
        (timeSlot.booking_date !== quoteDetails?.booking_date || timeSlot.time_slot !== quoteDetails?.time_slot)) ||
      (!!deliveryAddress && formatAddress(deliveryAddress) !== formatAddress(quoteDetails?.delivery_address))
    ) {
      setAcceptBtn(QuoteAction.CONFIRM_BOOKING)
      acceptSelector?.classList.add('quote-accept')
    } else if (snapValue === QuoteStep.PAYMENT) {
      setAcceptBtn(QuoteAction.GO_TIMESLOT)
      acceptSelector?.classList.remove('quote-accept')
    } else if (snapValue === QuoteStep.TIMESLOT) {
      if (quoteDetails?.invoice_data?.payment_state !== PaymentStatus.PAID) {
        if (paymentOption.p_option === PaymentOptionEnum.MONTH_INSTALLMENT) {
          setAcceptBtn(paymentOption.detail)
        } else {
          setAcceptBtn(QuoteAction.GO_PAYMENT)
        }
      } else {
        if (
          timeSlot?.booking_date !== quoteDetails?.booking_date ||
          timeSlot?.time_slot !== quoteDetails?.time_slot ||
          formatAddress(deliveryAddress) !== formatAddress(quoteDetails.delivery_address)
        ) {
          setAcceptBtn(QuoteAction.CONFIRM_BOOKING)
        } else {
          setAcceptBtn(QuoteAction.NONE)
        }
      }

      acceptSelector?.classList.add('quote-accept')
    }
  }, [snapValue, timeSlot, paymentOption, quoteDetails?.invoice_data])

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined
    if (callQuote && quoteDetails && id) {
      const isOnTheWay = quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.ON_THE_WAY - 1].date
      const isArrived = quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.ARRIVED - 1].date
      const isVehicleReady = quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.VEHICLE_READY - 1].date
      const isAllDone = quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.ALL_DONE - 1].date

      if (!isOnTheWay || !!isAllDone || !!isVehicleReady || !!isArrived) {
        setCallQuote(false)
        clearInterval(interval)
      } else {
        interval = setInterval(() => {
          getQuote()
        }, 10000)
      }
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [callQuote, quoteDetails, id])

  useEffect(() => {
    if (!quoteDetails) {
      return
    }
    if (payl8rState === 'success') {
      if (quoteDetails?.fixglass_payment_state === PaymentStatus.PAID) {
        toast.success('Payment success')
      } else {
        setPaymentMethodStatus(true)
      }
    }
    if (payl8rState === 'fail' && quoteDetails?.fixglass_payment_state === PaymentStatus.NOT_PAID) {
      toast.error('Payment failed')
    }
    setSearchParams({})
  }, [quoteDetails, payl8rState])

  useEffect(() => {
    if (!quoteDetails) {
      return
    }
    if (paPaymentState === 'success') {
      if (quoteDetails?.fixglass_payment_state === PaymentStatus.PAID) {
        toast.success('Payment success')
      } else {
        setPaymentMethodStatus(true)
      }
    }
    if (paPaymentState === 'fail' && quoteDetails?.fixglass_payment_state === PaymentStatus.NOT_PAID) {
      toast.error('Payment failed')
    }
    setSearchParams({})
  }, [quoteDetails, paPaymentState])

  useEffect(() => {
    if (!quoteDetails) {
      return
    }
    if (stripePaymentState === 'success') {
      if (quoteDetails?.fixglass_payment_state === PaymentStatus.PAID) {
        toast.success('Payment success')
      } else {
        setPaymentMethodStatus(true)
      }
    }
    // if (stripePaymentState === 'fail' && quoteDetails?.fixglass_payment_state === PaymentStatus.NOT_PAID) {
    //   toast.error('Payment failed')
    // }
    setSearchParams({})
  }, [quoteDetails, stripePaymentState])

  if (snapValue === QuoteStep.TRACKING) {
    return (
      <>
        <Box sx={{ py: { xs: 2, lg: 4, backgroundColor: '#ffffff' } }}></Box>
        <QuoteTracking
          quoteDetails={quoteDetails!}
          callQuote={callQuote}
          setCallQuote={setCallQuote}
          refetch={() => {
            getQuote()
          }}
          payAssist={payAssistToParent}
          PADataToParent={PADataToParent}
          PAUrl={PAUrl}
          handleChangePaymentMethod={handleChangePaymentMethod}
          setEmailMissing={setEmailMissing}
          goToPayment={async () => {
            setGoToPaymentClicked(true)
          }}
          onSucceedPayment={() => {
            // setPaymentStripeSuccess(true)
            // setPaymentMethodStatus(true)
            const currentUrl = window.location.href
            window.location.href = `${currentUrl}?stripePayment=success`
          }}
          paymentMethodStatus={paymentMethodStatus}
          listQuestionCharacteristics={listQuestionCharacteristics}
        />
      </>
    )
  } else if (
    process.env.REACT_APP_PAYL8R_ENABLE === 'true' &&
    snapValue === QuoteStep.PAYMENT &&
    quoteDetails?.payment_method_type === PaymentMethodType.PAYL8R
  ) {
    return <Payl8r quoteDetails={quoteDetails} />
  } else {
    return (
      <div className='my-4 my-md-5'>
        {declinePopup && (
          <div className='popup-background'>
            <div className='popup-container'>
              <div className='popup-close-container'>
                <img className='popup-close' src={close} alt='' onClick={() => setDeclinePopup(false)} />
              </div>
              <div className='popup-decline-container'>
                <div>Reason for declining</div>
                <div
                  className={declineReason === 1 ? 'popup-decline-reason-act' : 'popup-decline-reason'}
                  onClick={() => selectDeclineReason(1)}
                >
                  1. Too expensive
                </div>
                <div
                  className={declineReason === 2 ? 'popup-decline-reason-act' : 'popup-decline-reason'}
                  onClick={() => selectDeclineReason(2)}
                >
                  2. Job already done
                </div>
                <input type='text' ref={declineRef} placeholder='3. Other' />
              </div>
              <div className='popup-btn-container'>
                {/* needs a function to take back */}
                <button className='btn btn-purple-outline mb-3' onClick={() => setDeclinePopup(false)}>
                  Close
                </button>
                <button className='btn btn-purple-radius mb-3' onClick={() => setDeclinePopup(false)}>
                  Take back decline
                </button>
              </div>
            </div>
          </div>
        )}

        <div className='center'>
          {quoteInfoOpen && (
            <div className='quote-info-main'>
              <div className='client-info-container'>
                {isBlink && (
                  <Tooltip disableFocusListener title='Booking confirmed'>
                    <div className='client-info-blink'>-</div>
                  </Tooltip>
                )}
                <div className='info-container'>
                  <div id='scroll-to-top'>
                    <div className='yellow-box' key={tempLicenseNum}>
                      <div className='blue-box'>
                        <img className='flag' src={flag} alt='' />
                        <div className='gb'>UK</div>
                      </div>
                      <input
                        className='license-input'
                        type='text'
                        defaultValue={tempLicenseNum}
                        placeholder='ENTER REG'
                        disabled={!!quoteDetails?.registration_number}
                      />
                    </div>
                    {!!quoteDetails?.vehicle_logo_url ? (
                      <Box display='flex' flexDirection='row' alignItems='center' gap={2} marginY={3}>
                        <Box sx={{ zoom: { xs: 0.8, lg: 1 } }}>
                          <CardMedia
                            component='img'
                            sx={{ width: 'auto', height: 48 }}
                            image={quoteDetails?.vehicle_logo_url}
                          />
                        </Box>
                        <div className='client-info'>{quoteDetails?.model}</div>
                      </Box>
                    ) : (
                      <div className='client-info'>
                        {quoteDetails?.make} {quoteDetails?.model}
                      </div>
                    )}

                    <div className='client-info'>
                      {quoteDetails?.customer_f_name} {quoteDetails?.customer_s_name}
                    </div>
                    {formatAddress(quoteDetails?.invoice_address) === formatAddress(quoteDetails?.delivery_address) ? (
                      <div className='client-info'>{formatAddress(quoteDetails?.invoice_address)}</div>
                    ) : (
                      <>
                        <div className='client-info'>
                          Invoice address: {formatAddress(quoteDetails?.invoice_address)}
                        </div>
                        <div className='client-info'>
                          Delivery address: {formatAddress(quoteDetails?.delivery_address)}
                        </div>
                      </>
                    )}

                    <div className='client-info'>{quoteDetails?.customer_email}</div>
                    <div className='client-info'>{quoteDetails?.customer_phone}</div>
                  </div>
                </div>
                {isQuotePayment ? null : (
                  <div className='edit-btn-container'>
                    <button className='edit-btn position-relative' onClick={backToCustomer}>
                      EDIT
                      {emailMissing && <span className='email-missing-error'>Add Email here</span>}
                    </button>
                  </div>
                )}
              </div>
              {renderPictures()}
              <div className='quote-info-bottom'>
                <div>
                  <div className='compact-bottom-row'>
                    <span className='quote-selected-windows'>
                      <b>Selected windows:</b>{' '}
                    </span>
                    {!!quoteDetails &&
                      quoteDetails?.glass_location?.map((element) => (
                        <span key={element} className='client-windows'>
                          {element?.charAt(0)?.toUpperCase() + element?.slice(1)}
                        </span>
                      ))}
                  </div>
                  {!!quoteDetails?.search_product_metric && (
                    <span className='quote-selected-windows'>
                      <b>OEE/OEM: {quoteDetails?.search_product_metric?.toString()?.toUpperCase()}</b>
                    </span>
                  )}
                  {listQuestionCharacteristics?.length > 0 && (
                    <Box sx={{ marginTop: { xs: 6, lg: 4 }, maxWidth: '420px', marginX: { xs: 0, lg: 4 } }}>
                      <Box
                        border={{ xs: '1px solid #D8D8DA', lg: 'unset' }}
                        borderRadius={{ xs: '6px', lg: 0 }}
                        p={{ xs: '12px 8px 8px 8px', lg: 0 }}
                        position='relative'
                        boxShadow={{
                          xs: '0px 4px 17px 0px rgba(147, 147, 147, 0.04), 0px 2px 12px 0px rgba(147, 147, 147, 0.07), 0px 1px 7px 0px rgba(147, 147, 147, 0.09)',
                          lg: 'unset',
                        }}
                        bgcolor={{ xs: '#F9FAFA', lg: 'unset' }}
                      >
                        <Questions characteristics={listQuestionCharacteristics} disabled onChange={() => {}} />
                      </Box>
                    </Box>
                  )}
                </div>

                <img
                  onClick={() => setInfoOpen(false)}
                  src={up}
                  alt=''
                  style={{ width: '17px' }}
                  className='client-up-icon'
                />
              </div>
            </div>
          )}
          {!quoteInfoOpen && (
            <div className='quote-info-compact'>
              {isBlink && (
                <Tooltip disableFocusListener title='Booking confirmed'>
                  <div className='client-info-blink'>-</div>
                </Tooltip>
              )}
              <div className='client-info-compact'>
                <div className='yellow-box' key={tempLicenseNum}>
                  <div className='blue-box'>
                    <img className='flag' src={flag} alt='' />
                    <div className='gb'>UK</div>
                  </div>
                  <input className='license-input' type='text' defaultValue={tempLicenseNum} placeholder='ENTER REG' />
                </div>
                <div className='client-info'>
                  {quoteDetails?.customer_f_name} {quoteDetails?.customer_s_name}
                </div>
                {renderPictures()}
                <div className='compact-bottom-row'>
                  {!!quoteDetails &&
                    quoteDetails?.glass_location?.map((element) => (
                      <span key={element} className='client-windows'>
                        {element}
                      </span>
                    ))}
                </div>
                {!!quoteDetails?.search_product_metric && (
                  <span className='quote-selected-windows'>
                    <b>OEE/OEM: {quoteDetails?.search_product_metric?.toString()?.toUpperCase()}</b>
                  </span>
                )}
                {listQuestionCharacteristics?.length > 0 && (
                  <Box sx={{ marginTop: { xs: 2, lg: 2 }, maxWidth: '420px', marginX: { xs: 0, lg: 4 } }}>
                    <Box
                      border={{ xs: '1px solid #D8D8DA', lg: 'unset' }}
                      borderRadius={{ xs: '6px', lg: 0 }}
                      p={{ xs: '12px 8px 8px 8px', lg: 0 }}
                      position='relative'
                      boxShadow={{
                        xs: '0px 4px 17px 0px rgba(147, 147, 147, 0.04), 0px 2px 12px 0px rgba(147, 147, 147, 0.07), 0px 1px 7px 0px rgba(147, 147, 147, 0.09)',
                        lg: 'unset',
                      }}
                      bgcolor={{ xs: '#F9FAFA', lg: 'unset' }}
                    >
                      <Questions characteristics={listQuestionCharacteristics} disabled onChange={() => {}} />
                    </Box>
                  </Box>
                )}
              </div>
              <img onClick={() => setInfoOpen(true)} className='client-info-icon' src={expand} alt='' />
            </div>
          )}
        </div>
        {!quoteDetails?.is_published && (
          <div className='center'>
            <h2 className='thank-you-header'>Thank you!</h2>
            <h1 className='extra-info'>We are preparing the quote...</h1>
            <img className='working-gif' src='https://media.tenor.com/6rG_OghPUKYAAAAM/so-busy-working.gif' alt='' />
          </div>
        )}

        <div className='true-top' id='1'>
          -
        </div>

        <div className='center'>
          <div className='scroll-container'>
            {/* select offer / payment method */}
            <div id='offer' className='mt-5'>
              {!!quoteDetails && (
                <PaymentMethod
                  offerDetails={quoteDetails.is_published ? offersDetails : [EMPTY_OFFER]}
                  optionalOrderLines={quoteDetails.is_published ? optionalOrderLines : []}
                  quoteDetails={{
                    ...quoteDetails,
                    c_address: formatAddress(quoteDetails?.invoice_address, false),
                    c_postalcode: quoteDetails?.invoice_address?.postcode || '',
                  }}
                  qid={id}
                  totalPrice={quoteDetails.is_published ? totalPrice : 0}
                  totalUnitPrice={quoteDetails.is_published ? totalUnitPrice : 0}
                  totalDiscountPrice={quoteDetails.is_published ? totalDiscountPrice : 0}
                  payAssist={payAssistToParent}
                  refetchQuote={getQuote}
                  PADataToParent={PADataToParent}
                  PAUrl={PAUrl}
                  handleChangePaymentMethod={handleChangePaymentMethod}
                  onCheckOptionalOrderLine={handleCheckOptionalOrderLine}
                  handleShowEmailMissing={() => {
                    setEmailMissing(true)
                    scrollToElementWithOffset('scroll-to-top')
                  }}
                  isResetPaymentMethod={isResetPaymentMethod}
                  setIsResetPaymentMethod={setIsResetPaymentMethod}
                  isQuotePayment={isQuotePayment}
                  goToPayment={() => setGoToPaymentClicked(true)}
                  onSucceedPayment={() => {
                    // setPaymentStripeSuccess(true)
                    // setPaymentMethodStatus(true)
                    const currentUrl = window.location.href
                    window.location.href = `${currentUrl}?stripePayment=success`
                  }}
                  paymentMethodStatus={paymentMethodStatus}
                />
              )}
            </div>

            <div className='quote-scroll-target' id='2'>
              -
            </div>

            {!!quoteDetails && (
              <div className='quote-card'>
                {(!!quoteDetails?.booking_date || !!quoteDetails?.request_booking_date) && !editBooking ? (
                  <div className='booking-info p-4'>
                    {!!quoteDetails?.booking_date && (
                      <>
                        <h1 className='mb-4'>You are booked in!</h1>
                        <div className='booking-address mb-4'>
                          Arriving {moment(quoteDetails?.booking_date).format('dddd, DD MMMM YYYY')} from{' '}
                          {slot2Time(quoteDetails.time_slot.split('_')?.[0])} to{' '}
                          {slot2Time(quoteDetails.time_slot.split('_')?.[1])}
                        </div>
                      </>
                    )}

                    {!quoteDetails?.booking_date && !!quoteDetails?.request_booking_date && (
                      <>
                        <h1 className='mb-4'>Request sent, we are now reviewing it!</h1>
                      </>
                    )}

                    {quoteDetails.working_place === WorkingPlace.MOBILE && (
                      <div className='booking-address'>
                        Booked in at{' '}
                        {quoteDetails.delivery_address?.line_1 || quoteDetails.delivery_address?.line_2 || 'N/A'},{' '}
                        {quoteDetails.delivery_address?.town_or_city || 'N/A'}{' '}
                        {quoteDetails.delivery_address?.county || 'N/A'}{' '}
                        {quoteDetails.delivery_address?.postcode || 'N/A'}
                      </div>
                    )}
                    {quoteDetails.working_place === WorkingPlace.WORKSHOP && (
                      <div className='booking-address'>Booked in at {quoteDetails.workshop_address || 'N/A'}</div>
                    )}
                    {/* {quoteDetails?.order_state !== OrderState.WON && (
                    <div className='d-flex justify-content-end'>
                      <button className='edit-btn' onClick={() => setEditBooing(true)}>
                        EDIT
                      </button>
                    </div>
                  )} */}
                    {!isQuotePayment && (
                      <div className='d-flex justify-content-end'>
                        <button className='edit-btn' onClick={() => setEditBooing(true)}>
                          EDIT
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <TimeSelection
                      timeSlotToParent={timeSlotToParent}
                      liveBooking={false}
                      bookingStartDate={
                        quoteDetails?.booking_date
                          ? moment(quoteDetails?.booking_date)
                              .add(quoteDetails.time_slot.split('_')?.[0], 'hours')
                              .format(BOOKING_DATE_FORMAT)
                          : moment(quoteDetails?.request_booking_date)
                              .add(quoteDetails.request_time_slot.split('_')?.[0], 'hours')
                              .format(BOOKING_DATE_FORMAT)
                      }
                    />
                    <LocationSelection
                      qid={id}
                      quoteInfo={quoteDetails}
                      deliveryAddressToParent={deliveryAddressToParent}
                    />
                    {(editBooking || timeSlotIsChanged || deliveryAddressIsChanged) && (
                      <div className='d-flex justify-content-center gap-4 mb-4'>
                        <button className='btn-stroked' onClick={() => handleCancelBookingChange()}>
                          Cancel
                        </button>
                        {timeSlot?.isFull ? (
                          <button className='btn-raised' onClick={() => handleRequestAvailability()}>
                            Request Availability
                          </button>
                        ) : (
                          <button className='btn-raised' onClick={() => handleConfirmBookingChange()}>
                            Request Booking
                          </button>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}

            {showButtons && (
              <div className='quote-scroll-target-2' id='3'>
                -
              </div>
            )}
          </div>
        </div>

        {/* Hide for now */}
        {showButtons && (
          <div className='accept-btn-container' id='accept-cont'>
            <button
              className='btn btn-purple-outline mb-3 quote-btn quote-decline'
              onClick={handleDecline}
              id='decline-btn'
            >
              Decline
            </button>
            {/* {quoteDetails?.order_state !== OrderState.WON && !!acceptBtn && (
            <button
              className='btn btn-purple-radius mb-3 quote-btn'
              onClick={() => handleSnapChange(acceptBtn)}
              id='accept-btn'
            >
              {acceptBtn}
            </button>
          )} */}
            {!isQuotePayment && !!acceptBtn && (
              <button
                className='btn btn-purple-radius mb-3 quote-btn'
                onClick={() => handleSnapChange(acceptBtn)}
                id='accept-btn'
              >
                {acceptBtn}
              </button>
            )}
          </div>
        )}

        {/* <BeforeAfter /> */}
        <Box sx={{ pt: 16, pb: 8 }}>
          <OurMethod
            beforeAfterType={quoteDetails ? BeforeAfterType.NEW_INQUIRY : BeforeAfterType.ALL}
            showVideos={false}
            registrationNumber={quoteDetails ? quoteDetails?.registration_number : undefined}
            glassLocation={quoteDetails ? quoteDetails?.glass_location : undefined}
          />
        </Box>

        <div className='center mb-5'>
          <h2 className='contact-message'>If you have questions please call {PHONE_NUMBER}</h2>
        </div>

        {!!warningMsg && (
          <ConfirmDialog
            title='Error'
            description={warningMsg}
            showCancel={false}
            confirmStr='Ok'
            onConfirm={() => setWarningMsg('')}
          />
        )}

        {showConfirmBooking && (
          <ConfirmDialog
            title='Confirm Request'
            description={
              <span className='text-left d-block'>
                Are you certain you want to make a booking for{' '}
                <strong>
                  {moment(timeSlot?.booking_date).format('dddd, MMMM Do')}, between{' '}
                  {moment(timeSlot?.booking_date).add(timeSlot?.time_slot.split('_')?.[0], 'hours').format('hh:mm A')}{' '}
                  and{' '}
                  {moment(timeSlot?.booking_date).add(timeSlot?.time_slot.split('_')?.[1], 'hours').format('hh:mm A')}
                </strong>
                ?
              </span>
            }
            onConfirm={() => {
              sendBookingData()
              setShowConfirmBooking(false)
            }}
            onCancel={() => setShowConfirmBooking(false)}
          />
        )}

        {showBookingMsg && (
          <ConfirmDialog
            title='You are booked in!'
            showIcon={false}
            description={
              <span className='text-left d-block'>
                Arriving{' '}
                {moment(quoteDetails?.booking_date || quoteDetails?.request_booking_date).format('dddd, MMMM Do')},
                between{' '}
                {moment(quoteDetails?.booking_date || quoteDetails?.request_booking_date)
                  .add(timeSlot?.time_slot.split('_')?.[0], 'hours')
                  .format('hh:mm A')}{' '}
                and{' '}
                {moment(quoteDetails?.booking_date || quoteDetails?.request_booking_date)
                  .add(timeSlot?.time_slot.split('_')?.[1], 'hours')
                  .format('hh:mm A')}
                <br />
                {quoteDetails?.delivery_address?.line_1}, {quoteDetails?.delivery_address?.town_or_city},{' '}
                {quoteDetails?.delivery_address?.postcode}
              </span>
            }
            subDescription={
              <span className='text-left d-block'>
                <br />
                Job will take 1-2 hours to complete. <br />
                We need to test wipers and/or door glass movement.
                <br />
                <br />
                How can you help our work process and quality: <br />
                Make sure we have enough space to fully open your vehicle doors and we can park our mid-sized van next.
                If not enough space we have to move to different location. If needed, remove dashcam, empty dashboard
                and/or door pockets. It will help us vacuum the shattered glass. <br />
                Then just let us do the rest and we will notify you when all is done. <br />
                <br />
                Any question, call {PHONE_NUMBER}
              </span>
            }
            showCancel={false}
            confirmStr='Ok'
            onConfirm={() => setShowBookingMsg(false)}
          />
        )}

        {showRequestAvailabilityMsg && (
          <ConfirmDialog
            title='Request sent!'
            showIcon={false}
            description={<span className='text-left d-block'>We are now reviewing it</span>}
            showCancel={false}
            confirmStr='Ok'
            onConfirm={() => setShowRequestAvailabilityMsg(false)}
          />
        )}
        {/* <iframe id='paymentFrame' style={{ width: '100%', height: '100vh' }} frameBorder='0'></iframe> */}
        {/* {!!quoteDetails && <Chat qid={id} />} */}
      </div>
    )
  }
}
