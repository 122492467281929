export enum FormFieldIds {
  REGISTRATION_NUMBER = 'registration_number',
  GLASS_LOCATION = 'glass_location',
  CHARACTERISTICS = 'inquiry_characteristics',
  COMMENT = 'comment',
  ATTACHMENTS = 'attachments',
  FIRST_NAME = 'customer_f_name',
  LAST_NAME = 'customer_s_name',
  EMAIL = 'customer_email',
  PHONE = 'customer_phone',
  CUSTOMER_ADDRESS = 'customer_address',
  CUSTOMER_COMMENTS = 'customer_comments',
}
