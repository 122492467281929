import { WinLoc } from '@glass/enums'

export const THREE_DOOR_COORDS: { [key: string]: string } = {
  [WinLoc.FRONT]:
    '151,429,183,389,279,356,391,340,453,340,575,358,663,391,698,428,626,718,599,732,243,726,219,714,176,576',
  [WinLoc.REAR]:
    '179,1338,208,1312,284,1358,565,1360,635,1311,674,1339,672,1383,620,1446,445,1475,258,1454,197,1415,174,1376,175,1355',
  [WinLoc.R1]: '641,716,732,605,709,438,651,674',
  [WinLoc.R2]: '645,722,732,619,718,1072,616,1087,626,848',
  [WinLoc.R3]: '617,1095,726,1078,663,1307,627,1302',
  [WinLoc.L1]: '203,707,131,443,116,611',
  [WinLoc.L2]: '130,641,196,710,216,834,228,1090,134,1073',
  [WinLoc.L3]: '131,1080,231,1095,213,1297,182,1302',
}
