import { Box, Button, CardMedia, Modal, Typography } from '@mui/material'

type QuoteLostDialogProps = {
  customerFirstName: string
  onConfirm: () => void
  onClose: () => void
  open: boolean
}

const QuoteLostDialog = ({ customerFirstName, onConfirm, open, onClose }: QuoteLostDialogProps) => {
  return (
    <Modal open={open} disableAutoFocus>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          height: 'auto',
          backgroundColor: 'white',
          borderRadius: '28px',
          maxWidth: '358px',
          p: 6,
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography
            fontSize={24}
            fontWeight={700}
            lineHeight='28px'
            letterSpacing='-0.24px'
            color='#14151F'
            textAlign='center'
          >
            {`Welcome back${!!customerFirstName ? `, ${customerFirstName}!` : '!'}`}
          </Typography>
          <Typography
            color='#474747'
            lineHeight='24px'
            letterSpacing='-0.16px'
            mt={4}
            textAlign='center'
            fontWeight={600}
          >
            Pick up where you left and continue with your inquiry.
          </Typography>
          <Button
            onClick={onConfirm}
            variant='contained'
            sx={{ mt: '40px', mb: '8px', textTransform: 'unset', fontSize: 18, fontWeight: 700, background: '#9557e8' }}
          >
            Yes, continue with it
            <CardMedia
              component='img'
              sx={{ width: 24, height: 'auto', marginRight: -2, ml: 1 }}
              image={process.env.PUBLIC_URL + '/img/arrow-right.svg'}
            />
          </Button>
          <Button onClick={onClose} sx={{ textTransform: 'unset', fontSize: 18, fontWeight: 700, color: '#9557e8' }}>
            No, I want to start new inquiry
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default QuoteLostDialog
