/* eslint-disable @typescript-eslint/no-explicit-any */
import { sortBy } from 'lodash'
import { Characteristic, Quote, QuoteDto } from '@glass/models'

function areArraysDifferent(arr1: any, arr2: any) {
  if (arr1.length !== arr2.length) return true

  for (let i = 0; i < arr1.length; i++) {
    if (!isObjectEqual(arr1[i], arr2[i])) {
      return true
    }
  }
  return false
}

function isObjectEqual(obj1: any, obj2: any) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }
  return true
}

export const getUpdatedFields = (postData: QuoteDto, quoteDetails: Quote) => {
  const updatedFields: string[] = []
  function isEqual(a: any, b: any): boolean {
    return JSON.stringify(a) === JSON.stringify(b)
  }

  for (const key in postData) {
    // check for address in customer_address of postData and invoice_address of quoteDetails
    if (postData.hasOwnProperty(key) && key === 'customer_address') {
      const newValue = (postData as any)['customer_address']
      const oldValue = (quoteDetails as any)['invoice_address']
      if (newValue?.latitude !== oldValue?.latitude || newValue?.longitude !== oldValue?.longitude) {
        updatedFields.push(key)
      }
    }
    // check for inquiry_characteristics in customer_address of postData and inquiry_characteristics of quoteDetails
    if (postData.hasOwnProperty(key) && key === 'inquiry_characteristics') {
      const newValue = (postData as any)['inquiry_characteristics']?.map((characteristic: Characteristic) => ({
        id: characteristic?.id,
        name: characteristic?.name,
        answer_yes: characteristic?.answer_yes,
        answer_no: characteristic?.answer_no,
        answer_not_know: characteristic?.answer_not_know,
        force_yes: false,
      }))
      const oldValue = (quoteDetails as any)['quote_characteristics']
      const oldValueQuestion: Characteristic[] = []
      if (oldValue) {
        Object.keys(oldValue)?.forEach((key) => {
          oldValue[key]?.forEach((characteristic: Characteristic) => {
            // oldValueQuestion?.push({ ...characteristic })
            oldValueQuestion?.push({
              id: characteristic?.id,
              name: characteristic?.name,
              answer_yes: characteristic?.answer_yes,
              answer_no: characteristic?.answer_no,
              answer_not_know: characteristic?.answer_not_know,
              force_yes: false,
            })
          })
        })
      }
      if (areArraysDifferent(sortBy(newValue, ['id'], ['asc']), sortBy(oldValueQuestion, ['id'], ['asc']))) {
        updatedFields.push(key)
      }
    }
    // another cases diff inquiry_characteristics and customer_address and customer_comments
    if (
      postData.hasOwnProperty(key) &&
      key !== 'inquiry_characteristics' &&
      key !== 'customer_address' &&
      key !== 'customer_comments'
    ) {
      const newValue = (postData as any)[key]
      const oldValue = (quoteDetails as any)[key]

      if (typeof newValue === 'object' && newValue !== null && !Array.isArray(newValue)) {
        for (const subKey in newValue) {
          if (!isEqual(newValue[subKey], oldValue?.[subKey])) {
            updatedFields.push(key)
            break
          }
        }
      } else if (Array.isArray(newValue) && !isEqual(newValue?.sort(), oldValue?.sort())) {
        updatedFields.push(key)
      } else if (!isEqual(newValue, oldValue)) {
        updatedFields.push(key)
      }
    }
  }
  return updatedFields
}

const fieldToDisplayNameMap: { [key: string]: string } = {
  customer_f_name: 'First name',
  customer_s_name: 'Last name',
  customer_phone: 'Phone number',
  customer_email: 'Email address',
  customer_address: 'Invoice address',
  registration_number: 'Registration number',
  glass_location: 'Glass location',
  customer_comments: 'Comments',
  inquiry_characteristics: 'Characteristics questions',
  search_product_metric: 'OEE/OEM questions',
}

export const getDisplayName = (field: string) => {
  return fieldToDisplayNameMap[field] || field
}

export const formatPaymentPrice = (value: number) => {
  if (value % 1 === 0) {
    return value.toFixed(0)
  }
  return value.toFixed(2)
}

export const mapGlassLocationPostBE = (glassLocation: string[]) => {
  if (glassLocation?.length === 0) {
    return []
  }
  return glassLocation?.map((item) => {
    if (item === 'left front quarter' || item === 'right front quarter') {
      return `${item} (vent)`
    }
    return item
  })
}

export const mapGlassLocationFromBE = (glassLocation: string[]) => {
  if (glassLocation?.length === 0) {
    return []
  }
  return glassLocation?.map((item) => {
    if (item === 'left front quarter (vent)' || item === 'right front quarter (vent)') {
      return item?.replace('(vent)', '')?.trim()
    }
    return item
  })
}

export const getMatchingCharacteristics = (
  originCharacteristics: { [key: string]: any[] },
  characteristics: { [key: string]: any[] },
) => {
  const matchingCharacteristics: { [key: string]: any[] } = {}

  Object.keys(originCharacteristics).forEach((key) => {
    if (characteristics[key]) {
      matchingCharacteristics[key] = characteristics[key]
    }
  })

  return matchingCharacteristics
}

export const mapFilterCharacteristics = (
  originCharacteristics: { [key: string]: Characteristic[] },
  characteristics: { [key: string]: Characteristic[] },
) => {
  const _newResData: { [key: string]: Characteristic[] } = {}

  Object.keys(originCharacteristics)?.map((key) => {
    const previousCharacteristicKey = characteristics?.[key]
    _newResData[key] = originCharacteristics[key]?.map((item) => {
      let _characteristic = { ...item, answer_not_know: false }
      const answerPreviousCharacteristic = previousCharacteristicKey?.find(
        (ch) => ch.id === item.id && ch.name === item.name,
      )
      if (answerPreviousCharacteristic)
        _characteristic = {
          ..._characteristic,
          ...answerPreviousCharacteristic,
          force_yes: _characteristic?.force_yes || answerPreviousCharacteristic?.force_yes,
        }
      return _characteristic
    })
  })
  return _newResData
}

export const checkHasAnsweredCharacteristics = (array: Characteristic[]) => {
  return array?.every(
    (item) => ((item.answer_yes || item.answer_no || item.answer_not_know) && !item.force_yes) || item?.force_yes,
  )
}

export const UKPhoneRegExp = /^(?:\+44|0)?\d{10,12}$/

export const compareGlassLocation = (oldArray: string[], newArray: string[]) => {
  let removed = newArray.filter((item) => !oldArray.includes(item))
  if (removed?.length > 0) {
    removed = removed?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))
  }
  let added = oldArray.filter((item) => !newArray.includes(item))
  if (added.length > 0) {
    added = added?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))
  }
  return { added, removed }
}
