import React from 'react'
import { Box, Radio, Typography } from '@mui/material'
import { CharacteristicAnswer } from '@glass/enums'
import { Characteristic } from '@glass/models'

export type QuestionCardProps = {
  characteristic: Characteristic
  disabled?: boolean
  hasError?: boolean
  onChange: (value: Characteristic) => void
}

export const QuestionCard: React.FC<QuestionCardProps> = ({
  characteristic,
  disabled = false,
  hasError = false,
  onChange,
}) => {
  const selectedAnswer = characteristic[CharacteristicAnswer.YES]
    ? CharacteristicAnswer.YES
    : characteristic[CharacteristicAnswer.NO]
    ? CharacteristicAnswer.NO
    : characteristic[CharacteristicAnswer.NOT_KNOW]
    ? CharacteristicAnswer.NOT_KNOW
    : undefined

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    onChange({
      ...characteristic,
      [CharacteristicAnswer.YES]: false,
      [CharacteristicAnswer.NO]: false,
      [CharacteristicAnswer.NOT_KNOW]: false,
      [value]: true,
    })
  }

  return (
    <>
      <Box
        sx={{
          padding: { xs: '12px', lg: '16px' },
          borderRadius: '2px',
          boxShadow:
            '0px 4px 17px 0px rgba(147, 147, 147, 0.04), 0px 2px 12px 0px rgba(147, 147, 147, 0.07), 0px 1px 7px 0px rgba(147, 147, 147, 0.09)',
          border: { xs: 'unset', lg: hasError ? '1px solid #C22222' : 'none' },
          background: '#fff',
        }}
      >
        <Typography
          sx={{
            color: 'ar(--Gray-800, #14151F)',
            fontSize: { xs: 12, lg: 14 },
            fontWeight: '400',
            lineHeight: '150%',
            letterSpacing: '-0.16px',
          }}
        >
          {characteristic.name}
        </Typography>
        <Box display='flex' flexWrap='wrap' gap={2}>
          <Box display='flex' alignItems='center'>
            <Radio
              checked={selectedAnswer === CharacteristicAnswer.YES}
              onChange={handleChangeValue}
              value={CharacteristicAnswer.YES}
              disabled={disabled}
            />
            <Typography
              sx={{
                color: 'ar(--Gray-800, #14151F)',
                fontSize: { xs: 12, lg: 14 },
                fontWeight: '400',
                lineHeight: '150%',
                letterSpacing: '-0.16px',
              }}
            >
              Yes
            </Typography>
          </Box>
          <Box display='flex' alignItems='center'>
            <Radio
              checked={selectedAnswer === CharacteristicAnswer.NO}
              onChange={handleChangeValue}
              value={CharacteristicAnswer.NO}
              disabled={disabled}
            />
            <Typography
              sx={{
                color: 'ar(--Gray-800, #14151F)',
                fontSize: { xs: 12, lg: 14 },
                fontWeight: '400',
                lineHeight: '150%',
                letterSpacing: '-0.16px',
              }}
            >
              No
            </Typography>
          </Box>
          <Box display='flex' alignItems='center'>
            <Radio
              checked={selectedAnswer === CharacteristicAnswer.NOT_KNOW}
              onChange={handleChangeValue}
              value={CharacteristicAnswer.NOT_KNOW}
              disabled={disabled}
            />
            <Typography
              sx={{
                color: 'ar(--Gray-800, #14151F)',
                fontSize: { xs: 12, lg: 14 },
                fontWeight: '400',
                lineHeight: '150%',
                letterSpacing: '-0.16px',
              }}
            >
              I don&apos;t know
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}
