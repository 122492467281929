import { WinLoc } from '@glass/enums'

export const SEDAN_COORDS: { [key: string]: string } = {
  [WinLoc.FRONT]: '255,741,418,723,592,741,652,513,511,469,342,471,192,513',
  [WinLoc.REAR]: '228,1339,352,1376,500,1375,619,1343,594,1196,568,1182,278,1183,251,1199',
  [WinLoc.R1]: '622,663,703,619,698,500,664,513',
  [WinLoc.R2]: '592,913,598,792,624,669,702,628,704,868',
  [WinLoc.R3]: '592,926,595,1098,665,1147,709,1138,712,883',
  [WinLoc.R4]: '589,1108,630,1255,699,1248,702,1156,664,1156',
  [WinLoc.L1]: '225,659,183,519,144,513,139,622',
  [WinLoc.L2]: '143,631,225,667,247,794,256,913,133,874',
  [WinLoc.L3]: '257,924,254,1097,179,1148,141,1142,136,886',
  [WinLoc.L4]: '257,1110,217,1254,158,1247,148,1154,187,1154',
}
