export enum QuoteStep {
  PAYMENT = 'payment',
  TIMESLOT = 'timeslot',
  NEW = 'new',
  OPEN = 'open',
  OPTIONS = 'options',
  REBOOK = 'rebook',
  REQUEST_TO_CANCEL = 'request_to_cancel',
  CHECKOUT = 'checkout',
  TRACKING = 'tracking',
  CREATE_PENDING = 'create_pending',
  LOST = 'lost',
  WON = 'won',
}
