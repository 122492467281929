import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { trackPromise } from 'react-promise-tracker'
import { useParams } from 'react-router-dom'
import Lightbox from 'yet-another-react-lightbox'
import Counter from 'yet-another-react-lightbox/plugins/counter'
import Download from 'yet-another-react-lightbox/plugins/download'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import { AddPictures } from '@glass/components/AddPictures'
import { TrackTechnician } from '@glass/components/GoogleMap/TrackTechnician'
import { LicensePlate } from '@glass/components/LicensePlate'
import { PaymentMethod } from '@glass/components/quotePage/PaymentMethod'
import { WindowSelector } from '@glass/components/WindowSelector'
import { Questions } from '@glass/components/WindowSelector/Questions'
import { EMPTY_OFFER } from '@glass/constants'
import { CarType, FieldServiceSequence, PaymentMethodType, PaymentStatus, TrackingStep } from '@glass/enums'
import { useRetrieveVehDataTracking } from '@glass/hooks/useRetrieveVehData'
import { Attachment, Characteristic, PaymentOptionDto, Quote, QuoteDto, VanPosition } from '@glass/models'
// import { updatePaymentMethod } from '@glass/services/apis/update-payment-method.service'
import { getVanPosition } from '@glass/services/apis/get-van-position'
import { updateQuoteService } from '@glass/services/apis/update-quote.service'
import { formatAddress } from '@glass/utils/format-address/format-address.util'
import { bookingEndTime, bookingStartTime, scrollToElementWithOffset, workingPlaceLabel } from '@glass/utils/index'
import {
  isValidTimerGlassLocation,
  convertToLocalTimeNoTimeZone,
  isValidShowTextGlassLocation,
} from '@glass/utils/quote-tracking/quote-tracking.util'

import { getTrackTechnicianChecked, setTrackTechnicianChecked } from '@glass/utils/session/session.util'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/counter.css'
import { DownloadInvoice } from './DownloadInvoice'
import { ShareQuote } from './ShareQuote'

export type QuoteTrackingProps = {
  quoteDetails: Quote
  callQuote: boolean
  setCallQuote: (value: boolean) => void
  refetch?: () => void
  goToPayment?: () => void
  PADataToParent?: (value: (string | undefined)[]) => void
  PAUrl?: string
  handleChangePaymentMethod?: (value: PaymentOptionDto) => void
  payAssist: (value?: PaymentMethodType) => void
  setEmailMissing: (value: boolean) => void
  onSucceedPayment?: () => void
  paymentMethodStatus?: boolean
  listQuestionCharacteristics?: Characteristic[]
}

export interface TrackingView {
  title: string
  icon: string
  isSmall: boolean
  isActive: boolean
  isCompleted: boolean
  date: string
}

export const QuoteTracking: React.FC<QuoteTrackingProps> = ({
  quoteDetails,
  callQuote,
  setCallQuote,
  refetch,
  PADataToParent,
  PAUrl,
  handleChangePaymentMethod,
  payAssist,
  setEmailMissing,
  goToPayment,
  onSucceedPayment,
  paymentMethodStatus,
  listQuestionCharacteristics,
}) => {
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  const { id: quoteId } = useParams()
  const [vanPosition, setVanPosition] = useState<VanPosition | null>(null)
  const [shouldCallVanApi, setShouldCallVanApi] = useState(true)
  const [brokenWindowsToComponent, setBrokenWindowsToComponent] = useState<string[]>([])

  const isOnTheWay = quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.ON_THE_WAY - 1]?.date
  const isArrived = quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.ARRIVED - 1]?.date
  const isVehicleReady = quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.VEHICLE_READY - 1]?.date
  const isAllDone = quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.ALL_DONE - 1]?.date

  const [showTrackTechnicianNotification, setShowTrackTechnicianNotification] = useState(
    !getTrackTechnicianChecked(quoteId),
  )
  const [modalTrackMap, setModalTrackMap] = useState(false)
  const [selectedCarType, setSelectedCarType] = useState<CarType>(CarType.THREE_DOOR)
  const [showFullAttachment, setShowFullAttachment] = useState<boolean>(false)
  const [indexAttachment, setIndexAttachment] = useState<number>(0)
  const [formattedTime, setFormattedTime] = useState<string>('')
  const [timeLeft, setTimeLeft] = useState<number>(0)

  const [attachments, setAttachments] = useState<Attachment[]>([])

  const isQuotePaid = useMemo(() => {
    return quoteDetails?.fixglass_payment_state === PaymentStatus.PAID
  }, [quoteDetails])

  const isQuotePaidPartial = useMemo(() => {
    return quoteDetails?.fixglass_payment_state === PaymentStatus.PARTIAL
  }, [quoteDetails])

  const preAttachments = useMemo(() => {
    let pictures: Attachment[] = []
    quoteDetails.customer_comments?.forEach((comment) => {
      pictures = pictures.concat(comment.attachments)
    })
    return pictures
  }, [quoteDetails])

  const getHeightStepBookingConfirm = useMemo(() => {
    if (Number(listQuestionCharacteristics?.length) > 0) {
      if (isLg) {
        return '380px'
      }
      return '370px'
    }
    return isLg ? '280px' : '250px'
  }, [listQuestionCharacteristics, isLg])

  const beforeAttachments = useMemo(() => {
    return (
      quoteDetails.tracking_delivery[0]?.state.find((item) => item.sequence === FieldServiceSequence.ARRIVED)
        ?.attachment_ids || []
    )
  }, [quoteDetails])

  const repairAttachments = useMemo(() => {
    return (
      quoteDetails.tracking_delivery[0]?.state.find((item) => item.sequence === FieldServiceSequence.MIDDLE_UPDATE)
        ?.attachment_ids || []
    )
  }, [quoteDetails])

  const afterAttachments = useMemo(() => {
    return (
      quoteDetails.tracking_delivery[0]?.state.find((item) => item.sequence === FieldServiceSequence.ALL_DONE)
        ?.attachment_ids || []
    )
  }, [quoteDetails])

  const listAttachments = useMemo(() => {
    return [...beforeAttachments, ...repairAttachments, ...afterAttachments]
  }, [beforeAttachments, repairAttachments, afterAttachments])

  const isShowAllDoneTimer = useMemo(() => {
    return timeLeft > 0 && timeLeft < 3600 && !!isAllDone && isValidTimerGlassLocation(quoteDetails?.glass_location)
  }, [timeLeft, isAllDone, quoteDetails, isValidTimerGlassLocation])

  const getTitleAllDone = useCallback(
    (isActive: boolean, isCompleted: boolean) => {
      if ((isActive || isCompleted) && isShowAllDoneTimer) {
        return `${formattedTime} until you can drive again`
      }
      return 'You can drive your car!'
    },
    [isShowAllDoneTimer, formattedTime],
  )

  const getTextStepAllDone = useCallback(
    (isActive: boolean, isCompleted: boolean) => {
      if (isActive || isCompleted) {
        if (isShowAllDoneTimer) {
          return 'Wait a little for the glue to dry out.'
        }
        return 'Everything is ready to drive! Enjoy it.'
      } else {
        if (isValidShowTextGlassLocation(quoteDetails?.glass_location)) {
          return 'Vehicle is safe to drive 60 min after new windscreen is installed. That is because we use 60 min crash-tested urethane.'
        }
        return ''
      }
    },
    [isShowAllDoneTimer, quoteDetails, isValidShowTextGlassLocation],
  )

  const steps = useMemo<{ [key: string]: TrackingView }>(() => {
    const bookingStart = bookingStartTime(quoteDetails.booking_date, quoteDetails.time_slot)
    const bookingEnd = bookingEndTime(quoteDetails.booking_date, quoteDetails.time_slot)
    const arrivedDate = quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.ARRIVED - 1]?.date
    const arrived = !!arrivedDate
    const vehicleReady = !!quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.VEHICLE_READY - 1]?.date
    const middleUpdate = !!quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.MIDDLE_UPDATE - 1]?.date
    const allDoneDate = quoteDetails?.tracking_delivery[0]?.state[FieldServiceSequence.ALL_DONE - 1]?.date
    const allDone = !!allDoneDate
    const getTitleBooking =
      quoteDetails?.fixglass_payment_state === PaymentStatus?.PAID
        ? 'Booking confirmed and paid'
        : 'Booking confirmed and not paid'

    return {
      [TrackingStep.BOOKING_CONFIRMED]: {
        title: getTitleBooking,
        icon: 'calendar-check.svg',
        isSmall: false,
        isActive: false,
        isCompleted: true,
        date: moment(quoteDetails?.date_order).format('MMM D'),
      },
      [TrackingStep.WAITING_SERVICE_DAY]: {
        title: 'Waiting for the day of the service',
        icon: 'check-tracking.svg',
        isSmall: true,
        isActive: false,
        isCompleted: moment().isAfter(moment(bookingStart).subtract(1, 'days')) || arrived,
        date: '',
      },
      [TrackingStep.DAY_BEFORE_REMINDER]: {
        title: 'Day before reminder',
        icon: 'check-tracking.svg',
        isSmall: true,
        isActive: moment().isAfter(moment(bookingStart).subtract(1, 'days')),
        isCompleted: moment().isAfter(moment(bookingStart)) || arrived,
        date: moment(bookingStart).subtract(1, 'days').format('MMM D'),
      },
      [TrackingStep.REPAIR_DAY]: {
        title: 'Repair day',
        icon: 'wrench-white.svg',
        isSmall: false,
        isActive: false,
        isCompleted: moment().isAfter(moment(bookingStart)) || arrived,
        date: moment(bookingStart).format('MMM D'),
      },
      [TrackingStep.ARRIVED]: {
        title: arrived ? 'Technician arrived' : !!isOnTheWay ? 'On the way!' : 'Technician will come to you',
        icon: 'check-tracking.svg',
        isSmall: true,
        isActive: moment().isAfter(moment(bookingStart)),
        isCompleted: arrived,
        date: !arrived
          ? arrivedDate
            ? convertToLocalTimeNoTimeZone(arrivedDate, 'h:mm a')
            : moment(bookingStart).format('h:mm a') + ' - ' + moment(bookingEnd).format('h:mm a') // Display "Coming Soon" if arrivedDate is invalid
          : convertToLocalTimeNoTimeZone(arrivedDate, 'h:mm a'),
      },
      [TrackingStep.BEFORE_PHOTO]: {
        title: 'Photo before starting the job',
        icon: 'check-tracking.svg',
        isSmall: true,
        isActive: arrived,
        isCompleted: middleUpdate,
        date: arrived && !beforeAttachments.length && !middleUpdate ? 'Now' : '',
      },
      [TrackingStep.REPAIRING]: {
        title: !!beforeAttachments.length ? 'Soon will be ready, all is going well' : 'Technician do the repair',
        icon: 'check-tracking.svg',
        isSmall: true,
        isActive: middleUpdate,
        isCompleted: vehicleReady,
        date: '',
      },
      [TrackingStep.AFTER_PHOTO]: {
        title: 'Photo when job is completed',
        icon: 'check-tracking.svg',
        isSmall: true,
        isActive: middleUpdate,
        isCompleted: vehicleReady,
        date: '',
      },
      [TrackingStep.ALL_DONE]: {
        title: getTitleAllDone(vehicleReady, allDone),
        icon: 'wheel-fill.svg',
        isSmall: false,
        isActive: allDone,
        isCompleted: allDone && !isShowAllDoneTimer,
        date: allDone ? moment(allDoneDate).format('MMM D') : '',
      },
    }
  }, [quoteDetails, getTitleAllDone, isShowAllDoneTimer, convertToLocalTimeNoTimeZone])

  useRetrieveVehDataTracking(quoteDetails?.DoorPlanLiteral, (data: CarType) => {
    setSelectedCarType(data)
  })

  const ColorStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean; isSmall?: boolean }
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.isSmall && {
      width: 12,
      height: 12,
      margin: '0 14px',
    }),
    ...(ownerState.active && {
      background: 'var(--Dark-Blue---Accent-500, #4522C2)',
    }),
    ...(ownerState.completed && {
      background: 'var(--Light-Blue---Primary-600, #133F85)',
    }),
  }))

  const ColorStepIcon = (props: StepIconProps) => {
    const step = props.icon as TrackingStep
    const { active, completed, className } = props
    const { isSmall, icon, date } = steps[step]

    return (
      <ColorStepIconRoot ownerState={{ completed, active, isSmall }} className={className}>
        {!!date && (
          <Typography
            sx={{
              position: 'absolute',
              left: 0,
              color: 'var(--Gray-700, #474747)',
              fontSize: step === TrackingStep.ARRIVED ? 10 : 12,
              lineHeight: '140%',
              width: '60px',
            }}
          >
            {date}
          </Typography>
        )}

        {isSmall && !completed ? '' : <img src={process.env.PUBLIC_URL + '/img/' + icon} />}
      </ColorStepIconRoot>
    )
  }

  const handleGetVanPosition = async (traccarId: string) => {
    if (!isOnTheWay || !!isAllDone || !!isVehicleReady || !!isArrived || !shouldCallVanApi) {
      return
    }
    try {
      const res = await getVanPosition(traccarId)
      if (res.success) {
        setVanPosition(res.data?.[0])
      }
    } catch (e) {
      return
    }
  }

  const handleChangeFiles = (files: Attachment[]) => {
    setAttachments(files)
  }

  const handleClickUpload = () => {
    const postData: QuoteDto = {
      customer_f_name: (quoteDetails?.customer_f_name || '').trim(),
      customer_s_name: (quoteDetails?.customer_s_name || '').trim(),
      customer_phone: (quoteDetails?.customer_phone || '').trim(),
      customer_email: (quoteDetails?.customer_email || '').trim(),
      registration_number: (quoteDetails?.registration_number || '').trim(),
      glass_location: (quoteDetails?.glass_location || []).map((item) => item.toLowerCase()),
      customer_comments: {
        comment: 'Upload Pictures',
        attachments: attachments,
      },
      inquiry_characteristics: listQuestionCharacteristics,
      search_product_metric: quoteDetails?.search_product_metric,
    }

    trackPromise(
      updateQuoteService({ fe_token: quoteId, ...postData }).then((res) => {
        if (res.success) {
          setAttachments([])
          refetch?.()
        }
      }),
    )
  }

  // const handlePayment = async (value: PaymentMethodType) => {
  //   if (quoteId) {
  //     trackPromise(
  //       updatePaymentMethod(quoteId, value).then((res) => {
  //         if (res.success) {
  //           if (value !== PaymentMethodType.STRIPE) {
  //             goToPayment?.()
  //           }
  //         }
  //       }),
  //       EnumLoader.PAYMENT_METHOD,
  //     )
  //   }
  // }

  const onClickShowAttachment = useCallback((index: number) => {
    setIndexAttachment(index)
    setShowFullAttachment(true)
  }, [])

  const getCountDownColor = () => {
    const isReadyToDrive = moment.utc(isAllDone, 'YYYY-MM-DD HH:mm:ss').add(1, 'hour')
    const now = moment()?.utc()
    const diffInSeconds = moment(isReadyToDrive).diff(now, 'seconds')

    const minutes = Math.floor(diffInSeconds / 60)
    const seconds = diffInSeconds % 60

    setFormattedTime(`${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`)
    setTimeLeft(diffInSeconds)
  }

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined
    if (timeLeft >= 0 && timeLeft < 3600 && !!isAllDone && isValidTimerGlassLocation(quoteDetails?.glass_location)) {
      interval = setInterval(() => {
        getCountDownColor()
      }, 1000)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timeLeft, isAllDone, quoteDetails, isValidTimerGlassLocation])

  useEffect(() => {
    if (quoteDetails?.tracking_delivery?.[0]?.traccar_device_id && shouldCallVanApi) {
      const traccarId = quoteDetails?.tracking_delivery?.[0]?.traccar_device_id
      handleGetVanPosition(traccarId)
    }
    const interval = setInterval(() => {
      if (quoteDetails?.tracking_delivery?.[0]?.traccar_device_id && shouldCallVanApi) {
        const traccarId = quoteDetails?.tracking_delivery?.[0]?.traccar_device_id
        handleGetVanPosition(traccarId)
      }
    }, 1000)

    if (!isOnTheWay || !!isAllDone || !!isVehicleReady || !!isArrived) {
      setShouldCallVanApi(false)
      setVanPosition(null)
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [shouldCallVanApi, quoteDetails])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (quoteDetails) {
      // send previously selected windows to window selection component
      const selectedWindows: string[] = []
      const selected = quoteDetails.glass_location
      if (selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          // capitalize first letter to match window name
          selectedWindows.push(selected[i].charAt(0).toUpperCase() + selected[i].slice(1))
        }
        setBrokenWindowsToComponent(selectedWindows)
      }
    }
  }, [quoteDetails])

  return (
    <>
      <Box sx={{ marginBottom: { xs: 40, lg: 0 }, mt: { lg: -8 } }}>
        <Box sx={{ display: { lg: 'flex' } }}>
          <Box sx={{ flex: 5 }}>
            <Box sx={{ borderBottom: '1px solid var(--Gray-100, #F2F2F3)', background: '#fff' }}>
              <Box
                className={isLg ? 'container-pl' : 'container'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  pt: { xs: 3, lg: 6 },
                  pb: { xs: 2, lg: 6 },
                  pr: { xs: 4, lg: 6 },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: 16, lg: 30 },
                    fontWeight: 700,
                    lineHeight: '140%',
                  }}
                >
                  Hello {quoteDetails.customer_f_name}!
                </Typography>

                <Box sx={{ position: 'relative' }}>
                  <Box sx={{ display: { lg: 'none' } }}>
                    <button
                      type='button'
                      className='btn-transparent primary p-0'
                      onClick={() => setModalTrackMap(true)}
                    >
                      <img src={process.env.PUBLIC_URL + '/img/map.svg'} />
                      <span style={{ fontSize: '16px', color: '#4285F4' }}>Track the technician</span>
                    </button>
                  </Box>
                  <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <ShareQuote url={`${process.env.PUBLIC_URL}/quote/${quoteId}`} />
                  </Box>
                  {quoteId && showTrackTechnicianNotification && (
                    <Box
                      sx={{
                        width: '133px',
                        px: 2,
                        py: 1,
                        position: 'absolute',
                        right: 0,
                        top: '32px',
                        borderRadius: '2px',
                        background: '#3B3B3B',
                        color: '#fff',
                        fontSize: 12,
                        lineHeight: '16px',
                      }}
                      onClick={() => {
                        setShowTrackTechnicianNotification(false)
                        setTrackTechnicianChecked(quoteId)
                      }}
                    >
                      Tap here to track our technician on the map.
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            {modalTrackMap && (
              <TrackTechnician
                onDismiss={() => setModalTrackMap(false)}
                deliveryAddress={quoteDetails.delivery_address}
                workshop={quoteDetails.workshop}
                vanPosition={vanPosition}
                vanNumber={quoteDetails.van_number}
                technicianName={quoteDetails.technician_name}
                isOnTheWay={!!isOnTheWay}
                setCallQuote={setCallQuote}
                callQuote={callQuote}
              />
            )}
            {modalTrackMap && <Typography>{vanPosition?.id}</Typography>}

            <Box
              className={isLg ? 'container-pl' : 'container'}
              sx={{ mb: 40, height: { lg: 'calc(100vh - 323px)' }, overflow: 'auto', pr: { xs: 4, lg: 6 } }}
            >
              {isQuotePaid || isQuotePaidPartial ? null : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      py: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'var(--Gray-600, #6A6B71)',
                        textAlign: 'center',
                        fontSize: { xs: 16, lg: 20 },
                        fontWeight: '600',
                        lineHeight: '140%',
                      }}
                    >
                      Hey, your job is booked but you haven&apos;t paid yet, please make payment
                    </Typography>
                  </Box>

                  <div id='offer' className='mt-2 mb-4'>
                    {!!quoteDetails && (
                      <PaymentMethod
                        offerDetails={[EMPTY_OFFER]}
                        optionalOrderLines={[]}
                        quoteDetails={{
                          ...quoteDetails,
                          c_address: formatAddress(quoteDetails?.invoice_address, false),
                          c_postalcode: quoteDetails?.invoice_address?.postcode || '',
                        }}
                        qid={quoteId}
                        totalPrice={quoteDetails?.amount_total}
                        totalUnitPrice={0}
                        totalDiscountPrice={0}
                        payAssist={payAssist}
                        refetchQuote={refetch!}
                        PADataToParent={PADataToParent}
                        PAUrl={PAUrl}
                        handleChangePaymentMethod={handleChangePaymentMethod}
                        onCheckOptionalOrderLine={() => {}}
                        handleShowEmailMissing={() => {
                          setEmailMissing(true)
                          scrollToElementWithOffset('scroll-to-top')
                        }}
                        isResetPaymentMethod={false}
                        setIsResetPaymentMethod={() => {}}
                        isQuotePayment={false}
                        goToPayment={() => goToPayment?.()}
                        hideInformationStatus
                        onSucceedPayment={onSucceedPayment}
                        paymentMethodStatus={paymentMethodStatus}
                      />
                    )}
                  </div>
                </>
              )}

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  py: 3,
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #6A6B71)',
                    textAlign: 'center',
                    fontSize: { xs: 16, lg: 20 },
                    fontWeight: '600',
                    lineHeight: '140%',
                  }}
                >
                  Service timeline
                </Typography>
              </Box>

              <div className='p-1 mb-3'>
                {quoteDetails.customer_comments.map((item, index) => (
                  <div key={index} className='text-left'>
                    <p>
                      <strong>Comment {index + 1}: </strong>
                      {item.comment}
                    </p>
                    <AddPictures size='small' disabled={true} attachments={item.attachments} />
                  </div>
                ))}
                {!preAttachments.length && !attachments.length && <div className='no-pictures'>No Pictures Added</div>}
                {!isAllDone && (
                  <AddPictures
                    size='small'
                    showUpload={true}
                    attachments={attachments}
                    onChangeFiles={handleChangeFiles}
                    onClickUpload={handleClickUpload}
                  />
                )}
              </div>

              <Box sx={{ pt: 5, pb: 50 }}>
                <Stepper
                  orientation='vertical'
                  sx={{
                    '.MuiStepLabel-iconContainer': {
                      position: 'relative',
                      paddingLeft: '48px',
                    },
                    '.MuiStepConnector-root:nth-child(14)': {
                      '.MuiStepConnector-line': { minHeight: '200px' },
                    },
                    '.MuiStepConnector-root:nth-child(2) .MuiStepConnector-line': {
                      minHeight: getHeightStepBookingConfirm,
                    },
                  }}
                  connector={
                    <StepConnector
                      sx={{
                        marginLeft: '66px',
                        '.MuiStepConnector-line': { borderLeftWidth: '4px', minHeight: '128px' },
                        '&.Mui-completed': { '.MuiStepConnector-line': { borderColor: '#133F85' } },
                        '&.Mui-active': { '.MuiStepConnector-line': { borderColor: '#133F85' } },
                      }}
                    />
                  }
                >
                  {Object.keys(steps).map((step) => {
                    const stepProps: { completed?: boolean } = {}
                    const active = steps[step].isActive
                    const completed: boolean = steps[step].isCompleted

                    return (
                      <Step key={step} {...stepProps} active={active} completed={completed}>
                        <StepLabel StepIconComponent={ColorStepIcon} icon={step} sx={{ padding: '4px 0' }}>
                          <Box sx={{ position: 'relative' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, height: '12px' }}>
                              {((step as TrackingStep) === TrackingStep.BEFORE_PHOTO ||
                                (step as TrackingStep) === TrackingStep.AFTER_PHOTO) && (
                                <CardMedia
                                  component='img'
                                  sx={{ width: 20, height: 20 }}
                                  image={
                                    process.env.PUBLIC_URL +
                                    '/img/' +
                                    (active || completed ? 'camera.svg' : 'map-marker.svg')
                                  }
                                />
                              )}
                              {(step as TrackingStep) === TrackingStep.ARRIVED && !!isOnTheWay && !isArrived ? (
                                <Button onClick={() => setModalTrackMap(true)}>
                                  <Typography
                                    sx={{
                                      color: completed
                                        ? 'var(--Gray-800, #14151F)'
                                        : active
                                        ? 'var(--Dark-Blue---Accent-500, #4522C2)'
                                        : 'var(--Gray-600, #6A6B71)',
                                      fontSize: 14,
                                      fontWeight: '600',
                                      lineHeight: '12px',
                                      textTransform: 'none',
                                    }}
                                  >
                                    {steps[step as TrackingStep].title}{' '}
                                  </Typography>
                                </Button>
                              ) : (
                                <Typography
                                  sx={{
                                    color: completed
                                      ? 'var(--Gray-800, #14151F)'
                                      : active
                                      ? 'var(--Dark-Blue---Accent-500, #4522C2)'
                                      : 'var(--Gray-600, #6A6B71)',
                                    fontSize: 14,
                                    fontWeight: '600',
                                    lineHeight: '12px',
                                  }}
                                >
                                  {steps[step as TrackingStep].title}{' '}
                                </Typography>
                              )}
                            </Box>
                            <Box width='100%' sx={{ position: 'absolute', marginTop: 3 }}>
                              {(step as TrackingStep) === TrackingStep.BOOKING_CONFIRMED && (
                                <>
                                  {!!quoteId && (
                                    <Box sx={{ marginTop: -2 }}>
                                      <DownloadInvoice quoteId={quoteId} />
                                    </Box>
                                  )}
                                  <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
                                    <Box sx={{ overflow: 'hidden', zoom: 0.3 }}>
                                      <WindowSelector
                                        carType={selectedCarType}
                                        setCarType={setSelectedCarType}
                                        brokenWindowsToComponent={brokenWindowsToComponent}
                                        disabledSelector
                                      />
                                    </Box>

                                    <Box maxWidth={isLg ? '50%' : '60%'}>
                                      <Box sx={{ display: 'flex', gap: 2 }}>
                                        {!!quoteDetails.vehicle_logo_url && (
                                          <CardMedia
                                            component='img'
                                            sx={{ width: 'auto', height: 20 }}
                                            image={quoteDetails.vehicle_logo_url}
                                          />
                                        )}
                                        <Typography
                                          sx={{
                                            color: 'var(--Gray-800, #14151F)',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '150%',
                                          }}
                                        >
                                          {quoteDetails?.make} {quoteDetails?.model}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          width: '140px',
                                          height: '40px',
                                          transform: 'scale(0.5)',
                                        }}
                                      >
                                        <LicensePlate
                                          licenseNumber={quoteDetails.registration_number}
                                          model={''}
                                          handleVehInputChange={() => {}}
                                          disabled
                                        />
                                      </Box>
                                      <Box>
                                        <Typography
                                          sx={{
                                            color: 'var(--Gray-600, #6A6B71)',
                                            fontSize: 14,
                                            lineHeight: '140%',
                                          }}
                                        >
                                          {quoteDetails.glass_location.length} glass selected (
                                          {quoteDetails.glass_location.join(', ')})
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {listQuestionCharacteristics && listQuestionCharacteristics?.length > 0 && (
                                    <Box
                                      sx={{ marginTop: { xs: 6, lg: 4 }, maxWidth: '420px', marginX: { xs: 0, lg: 4 } }}
                                    >
                                      <Box
                                        border={{ xs: '1px solid #D8D8DA', lg: 'unset' }}
                                        borderRadius={{ xs: '6px', lg: 0 }}
                                        p={{ xs: '12px 8px 8px 8px', lg: 0 }}
                                        position='relative'
                                        bgcolor={{ xs: '#F9FAFA', lg: 'unset' }}
                                      >
                                        <Questions
                                          characteristics={listQuestionCharacteristics}
                                          disabled
                                          onChange={() => {}}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                </>
                              )}

                              {(step as TrackingStep) === TrackingStep.WAITING_SERVICE_DAY && (
                                <Typography
                                  sx={{
                                    color:
                                      active || completed ? 'var(--Gray-800, #14151F)' : 'var(--Gray-600, #6A6B71)',
                                    fontSize: 12,
                                    lineHeight: '130%',
                                  }}
                                >
                                  You will be able to track your technician on live map
                                </Typography>
                              )}
                              {(step as TrackingStep) === TrackingStep.DAY_BEFORE_REMINDER && (
                                <Typography
                                  sx={{
                                    color:
                                      active || completed ? 'var(--Gray-800, #14151F)' : 'var(--Gray-600, #6A6B71)',
                                    fontSize: 12,
                                    lineHeight: '130%',
                                  }}
                                >
                                  You will receive a reminder that we are coming a day before service.
                                </Typography>
                              )}
                              {(step as TrackingStep) === TrackingStep.REPAIR_DAY && (
                                <Typography
                                  sx={{
                                    color:
                                      active || completed ? 'var(--Gray-800, #14151F)' : 'var(--Gray-600, #6A6B71)',
                                    fontSize: 12,
                                    lineHeight: '130%',
                                  }}
                                >
                                  Place your car on so there is nothing within 2m radius. Our technician needs space to
                                  work.
                                </Typography>
                              )}
                              {(step as TrackingStep) === TrackingStep.ARRIVED && (
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                  <CardMedia
                                    component='img'
                                    sx={{ width: 20, height: 20 }}
                                    image={
                                      process.env.PUBLIC_URL +
                                      '/img/' +
                                      (active || completed ? 'map-marker-blue.svg' : 'map-marker.svg')
                                    }
                                  />
                                  <Typography
                                    sx={{
                                      color:
                                        active || completed ? 'var(--Gray-800, #14151F)' : 'var(--Gray-600, #6A6B71)',
                                      fontSize: 12,
                                      lineHeight: '130%',
                                    }}
                                  >
                                    {formatAddress(quoteDetails.delivery_address)}
                                  </Typography>
                                </Box>
                              )}
                              {(step as TrackingStep) === TrackingStep.BEFORE_PHOTO && (
                                <>
                                  {active || completed ? (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {beforeAttachments?.map((item, index) => (
                                        <Button key={index} onClick={() => onClickShowAttachment(index)}>
                                          <CardMedia
                                            key={index}
                                            component='img'
                                            sx={{
                                              width: 'auto',
                                              height: 106,
                                              objectFit: 'cover',
                                              borderRadius: '2px',
                                            }}
                                            image={item.attachment_url}
                                            alt='Broken Image'
                                          />
                                        </Button>
                                      ))}
                                    </Box>
                                  ) : (
                                    <Typography
                                      sx={{
                                        color:
                                          active || completed ? 'var(--Gray-800, #14151F)' : 'var(--Gray-600, #6A6B71)',
                                        fontSize: 12,
                                        lineHeight: '130%',
                                      }}
                                    >
                                      Technician will take images of your car before repair
                                    </Typography>
                                  )}
                                </>
                              )}
                              {(step as TrackingStep) === TrackingStep.REPAIRING && (
                                <>
                                  {(completed || !!repairAttachments?.length) && (
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                      <CardMedia
                                        component='img'
                                        sx={{ width: 16, height: 16 }}
                                        image={process.env.PUBLIC_URL + '/img/check-gray.svg'}
                                      />
                                      <Box>
                                        <Typography
                                          sx={{
                                            color: 'var(--Gray-600, #6A6B71)',
                                            fontSize: 12,
                                            lineHeight: '130%',
                                            letterSpacing: '-0.12px',
                                          }}
                                        >
                                          Old glass and glue removed, surface prepared for new glass
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                          }}
                                        >
                                          {repairAttachments?.map((item, index) => (
                                            <Button
                                              key={beforeAttachments?.length + index}
                                              onClick={() => onClickShowAttachment(beforeAttachments?.length + index)}
                                            >
                                              <CardMedia
                                                key={beforeAttachments?.length + index}
                                                component='img'
                                                sx={{
                                                  width: 'auto',
                                                  height: 106,
                                                  objectFit: 'cover',
                                                  borderRadius: '2px',
                                                  marginTop: 2,
                                                }}
                                                image={item.attachment_url}
                                              />
                                            </Button>
                                          ))}
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                                  {completed && (
                                    <Box sx={{ display: 'flex', gap: 1, mt: 4 }}>
                                      <CardMedia
                                        component='img'
                                        sx={{ width: 16, height: 16 }}
                                        image={process.env.PUBLIC_URL + '/img/check-gray.svg'}
                                      />
                                      <Typography
                                        sx={{
                                          color: 'var(--Gray-600, #6A6B71)',
                                          fontSize: 12,
                                          lineHeight: '130%',
                                          letterSpacing: '-0.12px',
                                        }}
                                      >
                                        Soon it will be ready
                                      </Typography>
                                    </Box>
                                  )}
                                  {!completed && !!repairAttachments?.length && (
                                    <Box sx={{ display: 'flex', gap: 1, mt: 4 }}>
                                      <CardMedia
                                        component='img'
                                        sx={{ width: 16, height: 16 }}
                                        image={process.env.PUBLIC_URL + '/img/live-signifier.svg'}
                                      />
                                      <Typography
                                        sx={{
                                          color: 'var(--Gray-600, #6A6B71)',
                                          fontSize: 12,
                                          lineHeight: '130%',
                                          letterSpacing: '-0.12px',
                                        }}
                                      >
                                        Placing new glass
                                      </Typography>
                                    </Box>
                                  )}
                                  {!completed && !repairAttachments?.length && (
                                    <Box sx={{ display: 'flex', gap: 1, mt: 4 }}>
                                      <CardMedia
                                        component='img'
                                        sx={{ width: 16, height: 16 }}
                                        image={process.env.PUBLIC_URL + '/img/live-signifier.svg'}
                                      />
                                      <Typography
                                        sx={{
                                          color: 'var(--Gray-600, #6A6B71)',
                                          fontSize: 12,
                                          lineHeight: '130%',
                                          letterSpacing: '-0.12px',
                                        }}
                                      >
                                        Removing the old glass and preparing surface for fitting new glass
                                      </Typography>
                                    </Box>
                                  )}
                                  {!completed && !active && (
                                    <Typography
                                      sx={{
                                        color:
                                          active || completed ? 'var(--Gray-800, #14151F)' : 'var(--Gray-600, #6A6B71)',
                                        fontSize: 12,
                                        lineHeight: '130%',
                                      }}
                                    >
                                      Technician will repair your broken glass.
                                    </Typography>
                                  )}
                                </>
                              )}
                              {(step as TrackingStep) === TrackingStep.AFTER_PHOTO && (
                                <>
                                  {active || completed ? (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {afterAttachments?.map((item, index) => (
                                        <Button
                                          key={beforeAttachments?.length + repairAttachments?.length + index}
                                          onClick={() =>
                                            onClickShowAttachment(
                                              beforeAttachments?.length + repairAttachments?.length + index,
                                            )
                                          }
                                        >
                                          <CardMedia
                                            key={beforeAttachments?.length + repairAttachments?.length + index}
                                            component='img'
                                            sx={{
                                              width: 'auto',
                                              height: 106,
                                              objectFit: 'cover',
                                              borderRadius: '2px',
                                            }}
                                            image={item.attachment_url}
                                            alt='Broken Image'
                                          />
                                        </Button>
                                      ))}
                                    </Box>
                                  ) : (
                                    <Typography
                                      sx={{
                                        color:
                                          active || completed ? 'var(--Gray-800, #14151F)' : 'var(--Gray-600, #6A6B71)',
                                        fontSize: 12,
                                        lineHeight: '130%',
                                      }}
                                    >
                                      Technician will take the images after he repairs your car.
                                    </Typography>
                                  )}
                                </>
                              )}
                              {(step as TrackingStep) === TrackingStep.ALL_DONE && (
                                <Typography
                                  sx={{
                                    color:
                                      active || completed ? 'var(--Gray-800, #14151F)' : 'var(--Gray-600, #6A6B71)',
                                    fontSize: 12,
                                    lineHeight: '130%',
                                  }}
                                >
                                  {getTextStepAllDone(active, completed)}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
              </Box>
            </Box>

            <Box
              sx={{
                position: 'fixed',
                bottom: '0',
                left: '0',
                width: { xs: '100vw', lg: '41.66vw' },
                zIndex: '100',
                padding: 'var(--16, 16px) var(--16, 16px) 40px var(--16, 16px)',
                borderTop: '1px solid var(--Gray-100, #f2f2f3)',
                background: '#fff',
              }}
            >
              <Box display='flex' flexDirection='column' width={{ xs: '100%', lg: 480 }} ml='auto'>
                <Box display='flex' gap={2} alignItems='center'>
                  <Avatar />
                  <Box display='flex' flexDirection='column' width='100%'>
                    <Box display='flex' flexDirection='column'>
                      <Typography color='#6A6B71' fontSize={{ xs: 12, lg: 16 }}>
                        Windscreen Technician
                      </Typography>
                      <Box width='100%' display='flex'>
                        <Typography color='#14151F'>{quoteDetails?.technician_name}</Typography>
                        {/* <Box display='flex' gap={2} ml={4} alignItems='center'>
                          <img src={process.env.PUBLIC_URL + '/img/badge-check.svg'} className='img-fluid' alt='' />
                          <Typography fontSize={{ sx: 10, lg: 14 }} color='#138531'>
                            Certified Expert
                          </Typography>
                        </Box> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box mt={6}>
                  <Box fontSize={{ xs: 14, lg: 16 }} display='flex' gap={2} alignItems='center'>
                    <CardMedia
                      component='img'
                      sx={{ width: 20, height: 20 }}
                      image={process.env.PUBLIC_URL + '/img/wrench.svg'}
                    />
                    {workingPlaceLabel(quoteDetails.working_place)}
                  </Box>
                  <Box fontSize={{ xs: 14, lg: 16 }} display='flex' gap={2} alignItems='center'>
                    <CardMedia
                      component='img'
                      sx={{ width: 20, height: 20 }}
                      image={process.env.PUBLIC_URL + '/img/van.svg'}
                    />
                    Technician van reg. number:{' '}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '140px',
                        height: '40px',
                        transform: 'scale(0.5)',
                      }}
                    >
                      <LicensePlate
                        licenseNumber={quoteDetails.van_number || ''}
                        model={''}
                        handleVehInputChange={() => {}}
                        disabled
                      />
                    </Box>
                  </Box>
                </Box>
                {/* <Link to={'/quote-details/' + quoteId}>
                  <Button
                    sx={{
                      width: '100%',
                      paddingX: 6,
                      paddingY: 3,
                      fontSize: '18px',
                      fontWeight: '700',
                      lineHeight: '24px',
                      letterSpacing: '0.18px',
                      color: 'var(--Light-Blue---Primary-500, #225FC2)',
                      background: 'var(--Light-Blue---Primary-000, #E8F0FE)',
                      boxShadow:
                        '0px 3px 8px 0px rgba(88, 86, 94, 0.08), 0px 2px 4px 0px rgba(88, 86, 94, 0.10), 0px 1px 2px 0px rgba(88, 86, 94, 0.12)',
                      marginTop: 8,
                      textTransform: 'none',
                    }}
                  >
                    See all the service details
                    <CardMedia
                      component='img'
                      sx={{ width: 24, height: 24, marginLeft: 2 }}
                      image={process.env.PUBLIC_URL + '/img/arrow-right-light-blue.svg'}
                      alt='Minus'
                    />
                  </Button>
                </Link> */}
              </Box>
            </Box>
          </Box>

          <Box sx={{ flex: 7, display: { xs: 'none', lg: 'block' } }}>
            <TrackTechnician
              showOnModal={false}
              onDismiss={() => setModalTrackMap(false)}
              deliveryAddress={quoteDetails.delivery_address}
              workshop={quoteDetails.workshop}
              vanPosition={vanPosition}
              vanNumber={quoteDetails.van_number}
              technicianName={quoteDetails.technician_name}
              isOnTheWay={!!isOnTheWay}
              setCallQuote={setCallQuote}
              callQuote={callQuote}
            />
          </Box>
        </Box>
      </Box>
      <Lightbox
        open={showFullAttachment}
        close={() => setShowFullAttachment(false)}
        slides={listAttachments?.map((item) => ({ src: item?.attachment_url }))}
        plugins={[Fullscreen, Slideshow, Zoom, Counter, Download]}
        counter={{ container: { style: { top: 'unset', bottom: 0 } } }}
        index={indexAttachment}
      />
    </>
  )
}
