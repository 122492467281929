import { WinLoc } from '@glass/enums'

export const COUPE_COORDS: { [key: string]: string } = {
  [WinLoc.FRONT]: '200,666,413,648,651,666,695,506,640,426,426,350,284,389,188,447,150,496',
  [WinLoc.REAR]: '221,1075,247,1055,596,1057,627,1074,613,1308,557,1343,425,1361,304,1342,238,1315',
  [WinLoc.R1]: '659,655,762,597,756,518,694,528',
  [WinLoc.R2]: '634,1017,741,931,761,610,661,661,637,795',
  [WinLoc.R3]: '635,1028,642,1237,691,1189,742,939',
  [WinLoc.L1]: '189,650,147,506,97,518,87,607',
  [WinLoc.L2]: '190,658,90,619,103,934,218,1026,214,822',
  [WinLoc.L3]: '207,1244,218,1037,107,943,171,1223',
}
