import React from 'react'
import { Box, CardMedia, Modal, Typography } from '@mui/material'

export type QuoteBEDialog = {
  onConfirm: () => void
  licenseNum?: string
  onClose?: () => void
  isQuoteActive?: boolean
}

export const QuoteBEDialog: React.FC<QuoteBEDialog> = ({ onConfirm, licenseNum, onClose, isQuoteActive = true }) => {
  return (
    <Modal
      open={true}
      aria-labelledby='child-modal-title'
      disableAutoFocus={true}
      aria-describedby='child-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          height: 'auto',
          backgroundColor: '#EEF4F8',
          borderRadius: '12px',
          maxWidth: '450px',
          p: 6,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: '700',
                lineHeight: '28px',
                letterSpacing: '-0.24px',
              }}
            >
              We detected a quote
            </Typography>
            <CardMedia
              component='img'
              sx={{ width: 24, height: 24, cursor: 'pointer' }}
              image={process.env.PUBLIC_URL + '/img/close.svg'}
              onClick={onClose}
            />
          </Box>
          <CardMedia
            sx={{ width: 160, height: 160, mt: 8 }}
            component='img'
            image={process.env.PUBLIC_URL + '/img/car-ownership.svg'}
          />
          <Typography
            sx={{
              color: 'var(--Gray-700, #474747)',
              textAlign: 'center',
              fontWeight: '600',
              lineHeight: '24px',
              letterSpacing: '-0.16px',
              mt: 2,
              mb: 12,
            }}
          >
            An existing quote for your vehicle with registration number {licenseNum} already exists and it’s{' '}
            {isQuoteActive ? 'active' : 'lost'}.
          </Typography>
          <button
            className='btn-raised w-100'
            onClick={onConfirm}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
          >
            Open quote
            <CardMedia
              component='img'
              sx={{ width: 24, height: 'auto', marginRight: -2 }}
              image={process.env.PUBLIC_URL + '/img/arrow-right.svg'}
            />
          </button>
        </Box>
      </Box>
    </Modal>
  )
}
