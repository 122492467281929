import React from 'react'
import { Checkbox } from '@mui/material'
import { Offer, OptionalOrderLine } from '@glass/models'
import './select-offer.css'
import { formatPaymentPrice } from '@glass/utils/index'

const TAX_VALUE = 0.2

export type SelectOfferNewProps = {
  selectOfferToCustomer: Offer[]
  optionalOrderLines?: OptionalOrderLine[]
  totalPrice: number
  totalUnitPrice: number
  totalDiscountPrice: number
  onCheckOptionalOrderLine?: (orderLineId: number, optionalLineId: number, checked: boolean) => void
  disabled?: boolean
}

export const SelectOfferNew: React.FC<SelectOfferNewProps> = ({
  selectOfferToCustomer,
  optionalOrderLines = [],
  totalPrice,
  totalUnitPrice,
  totalDiscountPrice = 0,
  onCheckOptionalOrderLine,
  disabled,
}) => {
  return (
    <div className='select-offer'>
      <div className='table-container'>
        <table className='SO-table'>
          <tbody>
            {selectOfferToCustomer?.map((element, rowIndex) => (
              <tr key={`order-line-${rowIndex}`} className='bg-light'>
                <td className='so-info'>
                  <div className='info-div first-col'>
                    {element?.product
                      ? `New ${element?.product?.toLowerCase()} supplied and fitted`
                      : 'New supplied and fitted'}
                  </div>
                </td>
                <td className='so-info'>
                  <div className='info-div top-right'>
                    {element?.discount > 0 ? (
                      <span>£ {formatPaymentPrice(element?.price_unit * (1 + TAX_VALUE))}</span>
                    ) : (
                      <span>£ {formatPaymentPrice(element.price_subtotal * (1 + TAX_VALUE))}</span>
                    )}
                  </div>
                </td>
              </tr>
            ))}
            {optionalOrderLines.map((element, rowIndex) => (
              <tr key={`optional-order-line-${rowIndex}`}>
                <td className='so-info'>
                  <div className='info-div first-col'>
                    <Checkbox
                      checked={element.order_line_added}
                      onChange={(_event, value) => {
                        if (onCheckOptionalOrderLine)
                          onCheckOptionalOrderLine(element.order_line_id, element.optional_line_id, value)
                      }}
                      sx={{
                        margin: '-9px 0',
                        color: '#9a73dd',
                        '&.Mui-checked': {
                          color: '#9a73dd',
                        },
                      }}
                      disabled={disabled}
                    />
                    {element.product}
                  </div>
                </td>
                <td className='so-info'>
                  <div className='info-div top-right'>
                    {element.discount > 0 && (
                      <span className='original-price-discount'>
                        £ {formatPaymentPrice(element.price_unit * (1 + TAX_VALUE))}
                      </span>
                    )}
                    <span>
                      £ {formatPaymentPrice((element.price_unit * (100 - element.discount) * (1 + TAX_VALUE)) / 100)}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='total-container-out'>
        <div className='total-container-in bg-light'>
          {totalDiscountPrice > 0 && (
            <div className='total-row'>
              <span className='original-price-discount' style={{ textAlign: 'right', width: '100%' }}>
                £ {formatPaymentPrice((totalUnitPrice + totalDiscountPrice) * (1 + TAX_VALUE))}
              </span>
            </div>
          )}
          <div className='total-row'>
            <span className='original-price'>Discount</span>
            <span className='original-price'>£ {formatPaymentPrice(totalDiscountPrice * (1 + TAX_VALUE))}</span>
          </div>
          {/* <div className='total-row'>
            <span>Untaxed amount</span>
            <span>£ {formatPaymentPrice(totalUnitPrice)}</span>
          </div> */}
          {/* <div className='total-row'>
            <span>Tax 20%</span>
            <span>£ {formatPaymentPrice(totalPrice - totalUnitPrice)}</span>
          </div> */}
          <div className='total-row total-last'>
            <span className='total-bold'>Total</span>
            <span className='total-bold'>£ {formatPaymentPrice(totalPrice)}</span>
          </div>
        </div>
      </div>
      <div className='container-taxes-charges'>
        <span className='includes-taxes-charges'>Includes taxes and charges</span>
      </div>
    </div>
  )
}
