export enum OrderState {
  NEW = 'new',
  OPEN = 'open',
  CONFIRM = 'confirm',
  WON = 'won',
  LOST = 'lost',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  PAYMENT_EXPIRED = 'payment_expired',
  REQUEST_TO_CANCEL = 'request_to_cancel',
  TO_REBOOK = 'to_rebook',
  PAID_AND_BOOKED = 'paid_and_booked',
  PAID_AND_NOT_BOOKED = 'paid_and_not_booked',
}
