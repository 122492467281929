export enum PaymentMethodType {
  ASSIST_TWELVE_PAYMENT = 'assist_12_payment',
  STRIPE = 'stripe',
  CASH = 'cash',
  ASSIST_FOUR_PAYMENT = 'assist_4_payment',
  ASSIST_SIX_PAYMENT = 'assist_6_payment',
  ASSIST_NINE_PAYMENT = 'assist_9_payment',
  ASSIST_EIGHTEEN_PAYMENT = 'assist_18_payment',
  PAYL8R = 'payl8r',
}

export interface IMonthlyPay {
  id: number
  keyItem: PaymentMethodType
  title: string
  value: number
}
