import { Typography, Box, Radio } from '@mui/material'
import { SearchProductMetricAnswer } from '@glass/enums'
import { DifferenceOEE_OEM } from '../Help/DifferenceOEE_OEM'

const QuestionsSearchProductMetric = ({
  onChange,
  searchProductMetric,
  disabled = false,
}: {
  onChange: (value: string) => void
  searchProductMetric: boolean | string
  disabled?: boolean
}) => {
  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    onChange(value)
  }

  return (
    <Box sx={{ mt: 6 }} display={'initial'}>
      <Box
        sx={{
          padding: { xs: '12px', lg: '16px' },
          borderRadius: '2px',
          boxShadow:
            '0px 4px 17px 0px rgba(147, 147, 147, 0.04), 0px 2px 12px 0px rgba(147, 147, 147, 0.07), 0px 1px 7px 0px rgba(147, 147, 147, 0.09)',
          border: { xs: 'unset', lg: 'none' },
          background: '#fff',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              color: 'ar(--Gray-800, #14151F)',
              fontSize: { xs: 12, lg: 14 },
              fontWeight: '400',
              lineHeight: '150%',
              letterSpacing: '-0.16px',
              marginBottom: 2,
            }}
          >
            Cheaper aftermarket (OEE) glass as used by insurance or more expensive genuine (OEM) glass from the
            dealership? All glasses meet quality and safety standards.
          </Typography>
          <Box display='flex' flexWrap='wrap' gap={2} justifyContent={'center'}>
            <Box display='flex' alignItems='center'>
              <Radio
                checked={searchProductMetric === SearchProductMetricAnswer.OEE}
                onChange={handleChangeValue}
                value={SearchProductMetricAnswer.OEE}
                disabled={disabled}
              />
              <Typography
                sx={{
                  color: 'ar(--Gray-800, #14151F)',
                  fontSize: { xs: 12, lg: 14 },
                  fontWeight: '400',
                  lineHeight: '150%',
                  letterSpacing: '-0.16px',
                }}
              >
                OEE
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <Radio
                checked={searchProductMetric === SearchProductMetricAnswer.OEM}
                onChange={handleChangeValue}
                value={SearchProductMetricAnswer.OEM}
                disabled={disabled}
              />
              <Typography
                sx={{
                  color: 'ar(--Gray-800, #14151F)',
                  fontSize: { xs: 12, lg: 14 },
                  fontWeight: '400',
                  lineHeight: '150%',
                  letterSpacing: '-0.16px',
                }}
              >
                OEM
              </Typography>
            </Box>
          </Box>
          <DifferenceOEE_OEM />
        </Box>
      </Box>
    </Box>
  )
}

export default QuestionsSearchProductMetric
