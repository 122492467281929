import { ReactNode } from 'react'
import { Box, BoxProps, SxProps, Theme } from '@mui/material'

interface IBoxShadow extends BoxProps {
  children: ReactNode
  sx?: SxProps<Theme>
}
const BoxShadow = ({ children, sx, ...props }: IBoxShadow) => {
  return (
    <Box
      px={3}
      py={3}
      sx={{
        borderRadius: '6px',
        background: '#FFF',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export { BoxShadow }
