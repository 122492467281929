import { useEffect, useState, useRef } from 'react'
import { Box, Button } from '@mui/material'
import forge from 'node-forge'
import { trackPromise } from 'react-promise-tracker'
import { useParams } from 'react-router-dom'
import { REACT_APP_API_URL_V2 } from '@glass/envs'
import { Quote } from '@glass/models'
import { createPayl8rPaymentService } from '@glass/services/apis/create-payl8r-payment.service'

const Payl8r = ({ quoteDetails }: { quoteDetails: Quote }) => {
  const { id: quoteId } = useParams()
  const currentUrl = window.location.href

  const [encryptedJson, setEncryptedJson] = useState<string>('')
  const [itemsEncoded, setItemsEncoded] = useState<string[]>([])
  const [isDataReady, setIsDataReady] = useState<boolean>(false)
  const [createSuccess, setCreateSuccess] = useState<boolean>(false)

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const loadPemFile = async () => {
    try {
      const timestamp = new Date().getTime()

      const listItemData = quoteDetails?.order_lines?.map((item) => ({
        description: item?.product,
        quantity: item?.product_uom_qty,
        price: item?.price_total * item?.product_uom_qty,
      }))

      const json = JSON.stringify({
        username: process.env.REACT_APP_PAYL8R_USERNAME,
        request_data: {
          api_version: '1.1',
          return_urls: {
            abort: `${currentUrl}?payl8r=fail`,
            fail: `${currentUrl}?payl8r=fail`,
            success: `${currentUrl}?payl8r=success`,
            return_data: `${REACT_APP_API_URL_V2}invoice/payment/payl8r/${quoteId}/webhook`,
          },
          request_type: 'standard_finance_request',
          test_mode: 0,
          order_details: {
            order_id: `${quoteId}-${timestamp}`,
            currency: 'GBP',
          },
          customer_details: {
            firstnames: quoteDetails.customer_f_name,
            surname: quoteDetails.customer_s_name,
            email: quoteDetails.customer_email,
            phone: quoteDetails.customer_phone,
            address: quoteDetails.delivery_address?.line_1,
            city: quoteDetails.delivery_address?.town_or_city,
            country: 'UK',
            postcode: quoteDetails.delivery_address?.postcode,
            delivery_address: quoteDetails.delivery_address?.line_1,
            delivery_city: quoteDetails.delivery_address?.town_or_city,
            delivery_postcode: quoteDetails.delivery_address?.postcode,
            delivery_country: 'UK',
          },
        },
      })

      const itemData = JSON.stringify(listItemData)

      const publicKey = forge.pki.publicKeyFromPem(process.env.REACT_APP_PAYL8R_PEM_KEY as string)
      const encryptedJsonBuffer = forge.util.encode64(publicKey.encrypt(forge.util.encodeUtf8(json)))
      setEncryptedJson(encryptedJsonBuffer)

      const splitJsonStrings = itemData.match(/.{1,800}/g) || []
      const itemsEncodedArray = splitJsonStrings.map((jsonString) => {
        const encryptedItem = forge.util.encode64(publicKey.encrypt(forge.util.encodeUtf8(jsonString)))
        return encryptedItem
      })
      setItemsEncoded(itemsEncodedArray)
      setIsDataReady(true)
    } catch (error) {
      console.error('Error loading or encrypting data:', error)
    }
  }

  useEffect(() => {
    if (quoteDetails && quoteId) {
      trackPromise(loadPemFile())
    }
  }, [quoteDetails])

  useEffect(() => {
    if (createSuccess && isDataReady && buttonRef?.current) {
      buttonRef.current.click()
    }
  }, [createSuccess, isDataReady, buttonRef?.current])

  useEffect(() => {
    if (quoteId && quoteDetails) {
      trackPromise(
        createPayl8rPaymentService({ fe_token: quoteId }).then((res) => {
          if (res.success) {
            setCreateSuccess(true)
          }
        }),
      )
    }
  }, [quoteId, quoteDetails])

  return (
    <Box width='100%' height='calc(100vh - 100px)'>
      {isDataReady ? (
        <Box display='none'>
          <form target='payl8r' action='https://payl8r.com/process' method='post'>
            <input type='hidden' name='data' value={encryptedJson} />
            {itemsEncoded.map((item, index) => (
              <input key={index} type='hidden' name='item_data[]' value={item} />
            ))}
            <input type='hidden' name='rid' value={process.env.REACT_APP_PAYL8R_USERNAME} />
            <Button ref={buttonRef} type='submit' sx={{ p: 0, textTransform: 'unset', width: '100%' }}></Button>
          </form>
        </Box>
      ) : (
        <p>Loading and encrypting data...</p>
      )}
      <iframe src='' height='100%' width='100%' title='Payl8r Payment' name='payl8r'></iframe>
    </Box>
  )
}

export { Payl8r }
