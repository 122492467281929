import './App.css'
import React, { useEffect, useState } from 'react'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/system'
import { usePromiseTracker } from 'react-promise-tracker'
import { Routes, Route, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Footer } from '@glass/components/Footer'
import { Header } from '@glass/components/Header'
import { Loader } from '@glass/components/Loader'
import { PaymentAssist } from '@glass/components/PaymentAssist'
import { Contact } from '@glass/pages/Contact'
import { Customer } from '@glass/pages/Customer'
import { Home } from '@glass/pages/Home'
import { Paid } from '@glass/pages/Paid'
import { Pricing } from '@glass/pages/Pricing'
import { QuotePage } from '@glass/pages/Quote'
import { Services } from '@glass/pages/Services'
import { setRequestedURL } from '@glass/utils/session/session.util'
import 'react-toastify/dist/ReactToastify.css'
import { CustomerFormBE } from './pages/CustomerFormBE'
import { PaRedirection } from './pages/PaRedirection/PaRedirection'
// import { Payl8rPage } from './pages/Payl8r'
import { QuotePagePreviewBE } from './pages/Quote/QuotePreviewBE'

export const LoadingIndicator: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker()
  return <Loader loading={promiseInProgress} />
}

const theme = createTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: [
      'Nunito Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#9557e8', // Define your custom color
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'var(--Gray-800, #14151F)',
        },
        h3: {
          fontWeight: '700',
        },
        h4: {
          fontWeight: '700',
        },
        h5: {
          fontWeight: '700',
        },
        h6: {
          fontWeight: '700',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'var(--Light-Blue---Primary-500, #225fc2)',
          '&:hover': {
            color: 'var(--Light-Blue---Primary-500, #225fc2)',
          },
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#9557e8',
          '&.Mui-checked': {
            color: '#9557e8',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
        },
        contained: {
          backgroundColor: '#9557e8',
          borderRadius: 6,
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#7239b3',
          },
          '&.Mui-disabled': {
            backgroundColor: '#f0e6ff',
            color: '#aaa',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          display: 'list-item',
          paddingLeft: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-input': {
            paddingBlock: '12px 12px',
            width: '100%',
            lineHeight: '24px',
            boxSizing: 'border-box',
            height: 'unset',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '4px 8px 4px 8px',
          borderRadius: '4px',
          background: '#3B3B3B',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#D8D8DA',
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiInput-underline:after': {
    //         borderBottomColor: '#9557e8', // Focus color for underline variant
    //       },
    //       '& .MuiOutlinedInput-root': {
    //         '&.Mui-focused fieldset': {
    //           borderColor: '#9557e8', // Focus color for outlined variant
    //         },
    //       },
    //     },
    //   },
    // },
  },
})

export const App: React.FC = () => {
  const location = useLocation()
  const [showMenu, setShowMenu] = useState<boolean>(true)
  // hide header and footer in BE
  const [showHeader, setShowHeader] = useState<boolean>(true)

  useEffect(() => {
    setShowHeader(
      !location.pathname.startsWith('/be_new_inquiry') && !location.pathname.startsWith('/quote/be/preview'),
    )
    setShowMenu(!location.pathname.startsWith('/quote') && !location.pathname.startsWith('/customer'))
    if (location.pathname === '/' && location.search) {
      setRequestedURL(window.location.href)
    }

    // if (location.pathname.startsWith('/payl8r-finance')) {
    //   window.scrollTo({ top: 0, behavior: 'smooth' })
    // }
  }, [location])

  return (
    <>
      <div className='main-content'>
        <div className='empty-test'>-</div>
        <LoadingIndicator />
        <ThemeProvider theme={theme}>
          {showHeader && <Header showMenu={showMenu} />}
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/customer' element={<Customer />} />
            <Route path='/customer/:licenseNum' element={<Customer />} />
            <Route path='/customer/edit/:licenseNum/:quoteId' element={<Customer editMode={true} />} />
            <Route path='/payment/assist/:status' element={<PaymentAssist />} />
            <Route path='/payment/:status' element={<PaymentAssist />} />
            <Route path='/paid' element={<Paid />} />
            <Route path='/quote/:id' element={<QuotePage />} />
            <Route path='/quote/in/:id' element={<QuotePage />} />
            <Route path='/quote/be/:id' element={<QuotePage quoteCount={false} />} />
            {/* <Route path='/payl8r-finance' element={<Payl8rPage />} /> */}
            <Route path='/payment/assist/success/:id' element={<PaRedirection isSuccess={true} />} />
            <Route path='/payment/assist/failed/:id' element={<PaRedirection />} />
            <Route path='/be_new_inquiry' element={<CustomerFormBE />} />
            <Route path='/quote/be/preview/:id' element={<QuotePagePreviewBE quoteCount={false} />} />
          </Routes>
          {showMenu && showHeader && <Footer />}
          <ToastContainer position='bottom-right' />
        </ThemeProvider>
      </div>
    </>
  )
}

export default App
