import { Modal, Typography, Box, Radio, SxProps, Theme } from '@mui/material'
import { SearchProductMetricAnswer } from '@glass/enums'
import { DifferenceOEE_OEM } from '../Help/DifferenceOEE_OEM'

const QuestionsSearchProductMetricPopup = ({
  onChange,
  searchProductMetric,
  disabled = false,
  sx,
}: {
  onChange: (value: string) => void
  searchProductMetric: boolean | string
  disabled?: boolean
  sx?: SxProps<Theme>
}) => {
  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    onChange(value)
  }

  return (
    <Modal open={true} disableAutoFocus={true} sx={sx}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          height: 'auto',
          backgroundColor: '#EEF4F8',
          borderRadius: '28px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          maxWidth: '760px',
          p: 6,
        }}
      >
        <Box>
          <Box sx={{ mt: 2 }} display={'initial'}>
            <Box
              sx={{
                position: 'relative',
                m: -2,
              }}
            >
              <Box
                sx={{
                  padding: { xs: '12px', lg: '16px' },
                  borderRadius: '2px',
                  boxShadow:
                    '0px 4px 17px 0px rgba(147, 147, 147, 0.04), 0px 2px 12px 0px rgba(147, 147, 147, 0.07), 0px 1px 7px 0px rgba(147, 147, 147, 0.09)',
                  border: { xs: 'unset', lg: 'none' },
                  background: '#fff',
                }}
              >
                <Box sx={{ textAlign: 'center' }}>
                  <Typography marginTop={0} variant='h5' fontWeight={'bold'} marginBottom={2}>
                    Have budget?
                  </Typography>
                  <Typography
                    sx={{
                      color: 'ar(--Gray-800, #14151F)',
                      fontSize: { xs: 12, lg: 14 },
                      fontWeight: '400',
                      lineHeight: '150%',
                      letterSpacing: '-0.16px',
                      marginBottom: 2,
                    }}
                  >
                    Cheaper aftermarket (OEE) glass as used by insurance or more expensive genuine (OEM) glass from the
                    dealership? All glasses meet quality and safety standards.
                  </Typography>
                  <Box display='flex' flexWrap='wrap' gap={2} justifyContent={'center'}>
                    <Box display='flex' alignItems='center'>
                      <Radio
                        checked={searchProductMetric === SearchProductMetricAnswer.OEE}
                        onChange={handleChangeValue}
                        value={SearchProductMetricAnswer.OEE}
                        disabled={disabled}
                      />
                      <Typography
                        sx={{
                          color: 'ar(--Gray-800, #14151F)',
                          fontSize: { xs: 12, lg: 14 },
                          fontWeight: '400',
                          lineHeight: '150%',
                          letterSpacing: '-0.16px',
                        }}
                      >
                        OEE
                      </Typography>
                    </Box>
                    <Box display='flex' alignItems='center'>
                      <Radio
                        checked={searchProductMetric === SearchProductMetricAnswer.OEM}
                        onChange={handleChangeValue}
                        value={SearchProductMetricAnswer.OEM}
                        disabled={disabled}
                      />
                      <Typography
                        sx={{
                          color: 'ar(--Gray-800, #14151F)',
                          fontSize: { xs: 12, lg: 14 },
                          fontWeight: '400',
                          lineHeight: '150%',
                          letterSpacing: '-0.16px',
                        }}
                      >
                        OEM
                      </Typography>
                    </Box>
                  </Box>
                  <DifferenceOEE_OEM />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default QuestionsSearchProductMetricPopup
