import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const PaRedirection = ({ isSuccess }: { isSuccess?: boolean }) => {
  const { id: quoteId } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    // Redirecting to quote page
    navigate(`/quote/${quoteId}?paPayment=${isSuccess ? 'success' : 'fail'}`)
  }, [])

  return <></>
}
