import { WinLoc } from '@glass/enums'

export const ESTATE_COORDS: { [key: string]: string } = {
  [WinLoc.FRONT]: '224,700,425,684,627,702,664,503,659,477,502,415,344,414,190,474,185,506',
  [WinLoc.REAR]: '214,1451,230,1383,277,1399,430,1405,580,1395,619,1380,635,1456,569,1499,430,1522,277,1503',
  [WinLoc.R1]: '639,667,723,621,729,500,670,515',
  [WinLoc.R2]: '637,677,721,631,724,843,615,892,619,766',
  [WinLoc.R3]: '612,920,610,1089,722,1094,726,884',
  [WinLoc.R4]: '611,1098,608,1138,679,1205,718,1202,721,1102',
  [WinLoc.R5]: '608,1150,703,1236,686,1383,642,1372,624,1325',
  [WinLoc.L1]: '210,674,174,509,112,499,117,615',
  [WinLoc.L2]: '236,893,231,769,213,683,119,626,118,844',
  [WinLoc.L3]: '239,924,240,1088,117,1092,116,890',
  [WinLoc.L4]: '242,1094,240,1140,172,1198,123,1197,119,1099',
  [WinLoc.L5]: '239,1153,228,1327,203,1373,159,1371,138,1227,177,1206',
}
