import { WinLoc } from '@glass/enums'

export const VANS_COORDS: { [key: string]: string } = {
  [WinLoc.FRONT]: '227,408,435,383,629,405,640,238,586,177,431,158,273,180,211,233',
  [WinLoc.R_REAR]: '427,1518,428,1546,653,1538,643,1509',
  [WinLoc.L_REAR]: '424,1518,424,1548,202,1539,211,1508',
  [WinLoc.R1]: '637,336,715,364,703,240,652,243',
  [WinLoc.R2]: '608,572,617,476,639,343,715,372,716,556',
  [WinLoc.R3]: '607,581,716,563,719,951,611,968',
  [WinLoc.R4]: '611,974,719,956,711,1340,624,1351',
  [WinLoc.R5]: '623,1355,712,1345,714,1479,630,1449',
  [WinLoc.L1]: '215,337,136,364,147,238,202,239',
  [WinLoc.L2]: '136,372,216,345,239,567,132,551',
  [WinLoc.L3]: '132,562,242,581,239,971,127,945',
  [WinLoc.L4]: '237,979,230,1346,119,1334,130,954',
  [WinLoc.L5]: '227,1352,223,1440,130,1480,124,1341',
}
