import { PaymentMethodType } from '@glass/enums'

export const MONTHLY_PAY_DATA = [
  {
    id: 1,
    keyItem: PaymentMethodType.ASSIST_FOUR_PAYMENT,
    value: 4,
    title: '4',
  },
  {
    id: 2,
    keyItem: PaymentMethodType.ASSIST_SIX_PAYMENT,
    value: 6,
    title: '6',
  },
  // {
  //   id: 3,
  //   keyItem: PaymentMethodType.ASSIST_NINE_PAYMENT,
  //   value: 9,
  //   title: '9',
  // },
  // {
  //   id: 4,
  //   keyItem: PaymentMethodType.ASSIST_TWELVE_PAYMENT,
  //   value: 12,
  //   title: '12',
  // },
  // {
  //   id: 5,
  //   keyItem: PaymentMethodType.ASSIST_EIGHTEEN_PAYMENT,
  //   value: 18,
  //   title: '18',
  // },
]
