import { Review } from '@glass/models'

export const GOOGLE_REVIEWS: Review[] = [
  {
    author_name: 'chami bob',
    profile_photo_url:
      'https://lh3.googleusercontent.com/a-/ALV-UjXIMIju78osuoaLLEmvyYcjRpT3s6PhsjTr2Ae2Nnru2td7wfU=s120-c-rp-mo-br100',
    author_url: 'https://www.google.com/maps/contrib/105310253233280254594',
    datetime: '2024-05-14T21:05:00Z',
    rating: 5,
    text: 'engineer arrived on time, friendly staff excellent. Highly recommend ⭐️⭐️⭐️⭐️⭐️',
    language: 'en',
    original_language: 'en',
    likes: 0,
  },
  {
    author_name: 'Ann Masters',
    profile_photo_url:
      'https://lh3.googleusercontent.com/a-/ALV-UjU3RG0eLxXn0u1ebuJJxZDRToOYreWWUrbx-EkIk0GXyjjsjoOp=s120-c-rp-mo-ba2-br100',
    author_url: 'https://www.google.com/maps/contrib/114475043271844193551',
    datetime: '2024-04-20T13:41:00Z',
    rating: 5,
    text: 'Having a smashed passenger door window and contacting several companies for a quote Fix glass, although unable to go directly through my insurance were less expensive and 4 days quicker than other glass replacement places 😀 they came to my house and fixed the window, vacuumed my car and were very professional. I would definitely recommend fixglass. Thanks',
    language: 'en',
    original_language: 'en',
    likes: 0,
  },
  {
    author_name: 'Wayne Dyas',
    profile_photo_url:
      'https://lh3.googleusercontent.com/a-/ALV-UjXdw3m8std8K_4eMyVLLBELPTNRt6tlziPjdoDUkxB_ZuyAorKM=s120-c-rp-mo-br100',
    author_url: 'https://www.google.com/maps/contrib/104822784438526729795',
    datetime: '2024-04-01T21:52:00Z',
    rating: 5,
    text: "I was happy with the quote they gave and as an added bonus there's an option to pay monthly. They rang me this morning (01-04-2024) and got everything sorted out over the phone including payment. I was booked in for Tuesday 2nd April and I was happy with that. Received a phone call a couple of hours later and asked me if I wanted new windscreen fitted today, I agreed and Arvin turned up at time quoted and he began to do his thing. I left him alone to get on with the job and he cracked on with it. He's a credit to your company and did a fantastic job. Thank you Arvin it was much appreciated.",
    language: 'en',
    original_language: 'en',
    likes: 0,
  },
  {
    author_name: 'Anna Clayton',
    profile_photo_url:
      'https://lh3.googleusercontent.com/a-/ALV-UjUdPtxxvRwMlOGCBr3PsPDAYacYhLA5f1oT9BvbOIfYmNt2Q8E=s120-c-rp-mo-br100',
    author_url: 'https://www.google.com/maps/contrib/114568823119854830635',
    datetime: '2024-01-26T00:56:00Z',
    rating: 5,
    text: "Really impressed by this company and Arvin's work. Not an easy job in January but he was punctual, polite, did a meticulous job and kept me well informed. Tristan was also very helpful on the phone. The work was also done at a far more reasonable price than that quoted by others. A bonus that it was a mobile service. Recommend!",
    language: 'en',
    original_language: 'en',
    likes: 0,
  },
  {
    author_name: 'Chris H',
    profile_photo_url:
      'https://lh3.googleusercontent.com/a/ACg8ocIOsJWuj9C79FHgQh_P5lhJ-lck7Tyai-4ETRcF3MoUqOrs=s120-c-rp-mo-br100',
    author_url: 'https://www.google.com/maps/contrib/107940179812032958570',
    datetime: '2023-12-14T23:57:00Z',
    rating: 5,
    text: 'Professional and pleasant guy. Gave a great service. Would highly recommend these guys. Well mannered. Credit to the company 👍',
    language: 'en',
    original_language: 'en',
    likes: 1,
  },
  {
    author_name: 'Jack Couldwell',
    profile_photo_url:
      'https://lh3.googleusercontent.com/a/ACg8ocKiOHk6PKPwKL6V2fcR6t1Wi6zaxga-wFhLacH8OoSHGfW23Q=s120-c-rp-mo-br100',
    author_url: 'https://www.google.com/maps/contrib/118432307971012257269',
    datetime: '2023-10-24T11:33:00Z',
    rating: 5,
    text: 'I don’t normally write reviews but I feel like this is necessary. Had my drivers side window put through. Gave these guys a call and they were out the next day. Supplied the window, come to my house and also cleaned up all of the glass inside the car for £200. Also comes with warranty just incase. Top service can’t recommend these guys enough.',
    language: 'en',
    original_language: 'en',
    likes: 0,
  },
  {
    author_name: 'Helen Thompson',
    profile_photo_url:
      'https://lh3.googleusercontent.com/a/ACg8ocLl8L5hdaIK1Z_8RagScioXsMNtkXfdf90SUaZSbR7W9CKl9w=s120-c-rp-mo-br100',
    author_url: 'https://www.google.com/maps/contrib/104073145157820032147',
    datetime: '2023-04-21T01:01:00Z',
    rating: 5,
    text: "I cannot recommend this company enough. Arvin kept me informed of when he was coming. He worked in the dark and didn't finish till gone 10.15. He has done a superb job and I think you would struggle to find a cheaper price. I will definitely recommend to family and friends and use you if ever I need a window on my car replacing again. So thank you",
    language: 'en',
    original_language: 'en',
    likes: 0,
  },
  {
    author_name: 'Helen Thompson',
    author_url: 'https://www.google.com/maps/contrib/104073145157820032147/reviews',
    language: 'en',
    original_language: 'en',
    profile_photo_url:
      'https://lh3.googleusercontent.com/a/AGNmyxaDBz36sd25GW9KiVGorX5K5-H16UYTfeMfd8tT=s128-c0x00000000-cc-rp-mo',
    rating: 5,
    text: "I cannot recommend this company enough. Arvin kept me informed of when he was coming. He worked in the dark and didn't finish till gone 10.15. He has done a superb job and I think you would struggle to find a cheaper price. I will definitely recommend to family and friends and use you if ever I need a window on my car replacing again. So thank you",
    time: 1682028088,
    translated: false,
  },
  {
    author_name: 'Shaunna Murphy',
    author_url: 'https://www.google.com/maps/contrib/114880543995725284944/reviews',
    language: 'en',
    original_language: 'en',
    profile_photo_url:
      'https://lh3.googleusercontent.com/a/AGNmyxZQgASHxvF1mOF31uGtUQxhAXUzjBbPON4PyFoA=s128-c0x00000000-cc-rp-mo',
    rating: 5,
    text: "Unfortunately got to my car after parking in a car park to see that the window had smashed. Was quite far from home and my insurance wasn't helping at all. I rang the company, they didn't have a window replacement in stock but they were able to temporarily fix me and send me on my way. From first phone call to driving home was under an hour, so impressed and can't thank you enough!",
    time: 1680294303,
    translated: false,
  },
  {
    author_name: 'Laura hastings',
    author_url: 'https://www.google.com/maps/contrib/114650644105140232914/reviews',
    language: 'en',
    original_language: 'en',
    profile_photo_url:
      'https://lh3.googleusercontent.com/a/AGNmyxbMWv-MhSBgvkAshaA_YSjQ_AQxNRuqbcJGBddJ=s128-c0x00000000-cc-rp-mo',
    rating: 5,
    text: 'Arvin was amazing this evening he was totally up against all weather but he never gave up. He made a stressful situation a lot easier. He was professional & polite. Thanks again so much will highly recommend.',
    time: 1679523257,
    translated: false,
  },
  // {
  //   author_name: 'geoff gibson',
  //   author_url: 'https://www.google.com/maps/contrib/103215002502545686178/reviews',
  //   language: 'en',
  //   original_language: 'en',
  //   profile_photo_url:
  //     'https://lh3.googleusercontent.com/a-/ACB-R5SKLqonCVfuUCd6ITwDUSkXGWRymjDFXFoomFU1=s128-c0x00000000-cc-rp-mo',
  //   rating: 5,
  //   text: 'Alvin arrived 19.45 carried out replacement in the rain like nothing was too much trouble,the guys work ethic is amazing if there is a job to do he will do it cannot recommend him highly enough and this was a same day replacement.thanks again arvin',
  //   time: 1677767938,
  //   translated: false,
  // },
  // {
  //   author_name: 'Mitchel White',
  //   author_url: 'https://www.google.com/maps/contrib/105236752064583223412/reviews',
  //   language: 'en',
  //   original_language: 'en',
  //   profile_photo_url:
  //     'https://lh3.googleusercontent.com/a/AGNmyxYuvPSN9vRe3VOADAkvuPSr-F68bCJtuCoCCcz2=s128-c0x00000000-cc-rp-mo',
  //   rating: 5,
  //   text: 'Great service, I called and was able to get an appointment the next day to replace my windscreen. Arvin was friendly and the windscreen looks great! Thank you so much.',
  //   time: 1675532021,
  //   translated: false,
  // },
  // {
  //   author_name: 'Виталик Харченко',
  //   profile_photo_url:
  //     'https://lh3.googleusercontent.com/a-/ALV-UjXqrLC3kPo3OpiRF1NhCi3MHyUkcGc5jPUnNqCT1QoaQEpDYr8=s120-c-rp-mo-br100',
  //   author_url: 'https://www.google.com/maps/contrib/106454011176778860885',
  //   datetime: '2023-01-21T11:22:00Z',
  //   rating: 5,
  //   text: 'the master knows his business exactly, works quickly, accurately and is very polite in communication.  The best service I have seen, I changed the meeting time several times, but I never heard a refusal.  Agreed to get to work even at 21:00.  Recommend!',
  //   language: 'en',
  //   original_language: 'en',
  //   likes: 0,
  // },
  // {
  //   author_name: 'Виталик Харченко',
  //   author_url: 'https://www.google.com/maps/contrib/106454011176778860885/reviews',
  //   language: 'en',
  //   original_language: 'en',
  //   profile_photo_url:
  //     'https://lh3.googleusercontent.com/a-/ACB-R5R50c91nDhTn9lYXJvoffjnZm-hhDkRsZXCos5z=s128-c0x00000000-cc-rp-mo',
  //   rating: 5,
  //   text: 'the master knows his business exactly, works quickly, accurately and is very polite in communication.  The best service I have seen, I changed the meeting time several times, but I never heard a refusal.  Agreed to get to work even at 21:00.  Recommend!',
  //   time: 1674289358,
  //   translated: false,
  // },
  // {
  //   author_name: 'Joe Chapman',
  //   author_url: 'https://www.google.com/maps/contrib/116326856987565483187/reviews',
  //   language: 'en',
  //   original_language: 'en',
  //   profile_photo_url:
  //     'https://lh3.googleusercontent.com/a-/ACB-R5Tz9B2JPpXKqazIudnF66WBE5JGJ_81VQCu0u-oYQ=s128-c0x00000000-cc-rp-mo',
  //   rating: 5,
  //   text: 'Absolutely great service, called up several garages and some gave me quotes almost 2x as much. He came same day of the busy Christmas period and did the job really quick. Really professional service and nice guy.',
  //   time: 1671477809,
  //   translated: false,
  // },
  // {
  //   author_name: 'Amy Macaulay',
  //   author_url: 'https://www.google.com/maps/contrib/112817683130230766863/reviews',
  //   language: 'en',
  //   original_language: 'en',
  //   profile_photo_url:
  //     'https://lh3.googleusercontent.com/a/AGNmyxbvy7jrppbg3IOy1BZlcX8-XO8cUxYSuPKy-QDU=s128-c0x00000000-cc-rp-mo',
  //   rating: 5,
  //   text: 'Fantastic service\nAll done through tx as-well so straight forward\nBooked Wednesday came to my house thursday and fitted it\nkept me updated on their arrival time\nSplit monthly pay too\nSo fast and easy 100% recommend these guys',
  //   time: 1668020836,
  //   translated: false,
  // },
  // {
  //   author_name: 'Wayne Steele',
  //   author_url: 'https://www.google.com/maps/contrib/115825047159161718500/reviews',
  //   language: 'en',
  //   original_language: 'en',
  //   profile_photo_url:
  //     'https://lh3.googleusercontent.com/a/AGNmyxaih97bG04hVBhVnQb2LspNbaWrSmBOtSX3eyZ3=s128-c0x00000000-cc-rp-mo',
  //   rating: 5,
  //   text: 'Had an accident around 23:00 pm the previous night and was completed the following day excellent service and friendly staff as well as being really reliable would highly recommend',
  //   time: 1665647501,
  //   translated: false,
  // },
]
